export const sortVersionObject = (obj) => {
  const sortedKeys = Object.keys(obj).sort((a, b) => {
    const aParts = a.split('.').map(Number)
    const bParts = b.split('.').map(Number)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      if (aParts[i] === undefined) return -1
      if (bParts[i] === undefined) return 1
      if (aParts[i] < bParts[i]) return -1
      if (aParts[i] > bParts[i]) return 1
    }

    return 0
  })

  const sortedObj = {}
  for (const key of sortedKeys) {
    sortedObj[key] = obj[key]
  }

  return sortedObj
}
