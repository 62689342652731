import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Text from './Text'

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: '8px',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

@observer
class Eula extends Component {
  render() {
    const { checkCheckbox, accepted, acceptEula, loading, t } = this.props
    return (
      <div>
        <Text height="calc(100vh - 350px)" />
        <div style={{ paddingLeft: '15%' }}>
          <Typography>{t('eula.proceedPrompt')}</Typography>
          <div style={styles.wrapper}>
            <FormControlLabel
              control={<Checkbox onChange={checkCheckbox} />}
              label={t('eula.acceptEula')}
            />
            <div style={styles.buttonWrapper}>
              <Button
                variant="contained"
                disabled={!accepted || loading}
                onClick={acceptEula}
              >
                {t('main.continue')}
              </Button>
              {loading && (
                <CircularProgress size={24} style={styles.buttonProgress} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Eula.propTypes = {
  checkCheckbox: PropTypes.func.isRequired,
  acceptEula: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  loading: PropTypes.bool,
}

export default withTranslation()(Eula)
