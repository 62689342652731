import decode from 'jwt-decode'

export function getTokenExpirationDate(token) {
  const decoded = decode(token)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp)
  return date
}

export function getIdAndEmail(token) {
  const decoded = decode(token)
  if (decoded) {
    return {
      email: decoded.name,
      userId: decoded.sub,
    }
  }
  return {}
}

export function getParam(token, param) {
  const decoded = decode(token)
  if (decoded) {
    return decoded[param]
  }
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token)
  if (date === null) {
    return false
  }
  return !(date.valueOf() > new Date().valueOf())
}
