import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import icon_manufacturer from './images/manufacturer_symbol.png'
import icon_dateOfManufacture from './images/date_of_manufacture.png'
import icon_serialNumber from './images/serial_number.png'
import icon_ce from './images/ce_marking.png'
import icon_udi from './images/udi-symbol.png'
import icon_electronicIfu from './images/electronic_ifu.png'
import icon_medicalDevice from './images/medical_device.png'
import icon_reference from './images/reference.png'
import packageJson from '../../../package.json'

import './about.scss'

class About extends Component {
  render() {
    const { t } = this.props
    const buildDate = new Date(
      packageJson['build-date'].split('-').reverse().join('.'),
    )
    console.log('buildDate', buildDate)
    return (
      <div className="about">
        <ul>
          <li>
            <img src={icon_manufacturer} />
            <div className="liText">
              <div className="liLabel">{t('about.manufacturer')}</div>
              {t('about.manufacturerInfo.name')}
              <br />
              {t('about.manufacturerInfo.address1')}
              <br />
              {t('about.manufacturerInfo.address2')}
              {t('about.manufacturerInfo.address3')}
              <br />
              {t('about.manufacturerInfo.country')}
              <br />
              <a
                href={t('about.manufacturerInfo.website')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('about.manufacturerInfo.website')}
              </a>
              <br />
            </div>
          </li>
          <li>
            <img src={icon_reference} />
            <div className="liText">
              <div className="liLabel">{t('about.reference')}</div>
              {t('about.referenceCopy')}
            </div>
          </li>
          <li>
            <img src={icon_dateOfManufacture} />
            <div className="liText">
              <div className="liLabel">{t('about.dateOfManufacture')}</div>
              {moment(buildDate).isValid()
                ? moment(buildDate).format('YYYY-MM-DD')
                : '-'}
            </div>
          </li>
          <li>
            <img src={icon_serialNumber} />
            <div className="liText">
              <div className="liLabel">{t('about.version')}</div>
              {packageJson.version || '-'}
            </div>
          </li>

          <li>
            <img src={icon_udi} />
            <div className="liText">
              <div className="liLabel">{t('about.UDI')}</div>
              {packageJson.udiVersion || '-'}
            </div>
          </li>

          <li>
            <img src={icon_ce} />
            <div className="liText">
              <div className="liLabel">{t('about.ce')}</div>
              {t('about.klasse')}
              <br />
            </div>
          </li>
          <li>
            <img src={icon_medicalDevice} />
            <div className="liText">
              <div className="liLabel">{t('about.medicalDevice')}</div>

              <br />
            </div>
          </li>
          <li>
            <img src={icon_electronicIfu} />
            <div className="liText">
              <div className="liLabel">{t('about.electronicIfu')}</div>
              <a
                href={t('about.electronicIfuLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('about.instructionsIfu')}
              </a>
              <br />
            </div>
          </li>
        </ul>
      </div>
    )
  }
}

export default withTranslation()(About)
