import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import AboutComponent from '../components/About'

@observer
class AboutPage extends Component {
  render() {
    return (
      <Fragment>
        <AboutComponent />
      </Fragment>
    )
  }
}

export default AboutPage
