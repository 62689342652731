import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import './index.scss'

const propTypes = {
  leftType: PropTypes.oneOf(['back', 'none', 'menu']),
  rightType: PropTypes.oneOf(['back', 'none', 'action', 'logout']),
  leftAction: PropTypes.func,
  rightAction: PropTypes.func,
  rightIcon: PropTypes.node,
  title: PropTypes.string,
  transparent: PropTypes.bool,
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  shadow: PropTypes.string,
}

const defaultProps = {
  leftType: 'back',
  rightType: 'none',
  transparent: false,
  theme: {
    background: 'linear-gradient(320deg, #A4F1CB 0%, #91E1D5 100%)',
  },
}

const styles = {
  appBar: {
    boxShadow: 'none',
  },
  title: {
    fontSize: '20px',
    fontWeight: 300,
    fontFamily: '"Arvo", "Helvetica"',
    textAlign: 'center',
    flexGrow: 1,
    color: '#000000',
  },
  iconlabel: {
    fontFamily: '"Open Sans", "Helvetica"',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    color: '#000000',
    alignSelf: 'center',
  },
}
styles.appbarTransparent = Object.assign({}, styles.appBar, {
  background: 'transparent',
})

class AppBar extends Component {
  theme = null
  constructor(props) {
    super(props)

    this.theme = Object.assign({}, defaultProps.theme, props.theme)
  }

  renderButton(type, action, rightIcon) {
    if (type === 'none') return
    let icon = null
    let label = null
    switch (type) {
      case 'menu':
        icon = <MenuIcon />
        break
      case 'back':
        icon = <ArrowBackIcon />
        break
      case 'logout':
        icon = <ExitToAppIcon />
        label = 'Logout'
        break
      case 'action':
        icon = rightIcon
        break
      default:
        break
    }

    return (
      <Fragment>
        <IconButton onClick={action}>{icon}</IconButton>
        {label && (
          <span style={styles.iconlabel} onClick={action}>
            {label}
          </span>
        )}
      </Fragment>
    )
  }

  render() {
    const {
      title,
      transparent,
      leftType,
      leftAction,
      rightType,
      rightAction,
      rightIcon,
      shadow,
    } = this.props

    const appbarRegular = Object.assign({}, styles.appBar, {
      background: `linear-gradient(to top, rgb(${
        shadow ? '102, 102, 102' : '255, 255, 255'
      }) 0%, rgba(251, 251, 251, 0) 25%), ${this.theme.background}`,
      paddingBottom: '5px',
    })
    return (
      <MaterialAppBar
        className="appBarComponent"
        position={transparent ? 'fixed' : 'static'}
        style={!transparent ? appbarRegular : styles.appbarTransparent}
      >
        <Toolbar>
          {this.renderButton(leftType, leftAction)}
          <Typography style={styles.title}>{title}</Typography>
          {this.renderButton(rightType, rightAction, rightIcon)}
        </Toolbar>
      </MaterialAppBar>
    )
  }
}

AppBar.propTypes = propTypes
AppBar.defaultProps = defaultProps

export default AppBar
