import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './index.scss'

class CurveFitLegenda extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { color = 'green', t } = this.props

    const styles = {
      circle: {
        borderRadius: '50%',
        height: '10px',
        border: `${color} solid 2px`,
        width: '10px',
      },
      line: {
        width: '25px',
        height: '2px',
        background: color,
        marginTop: '10px',
      },
      square: {
        width: '25px',
        height: '10px',
        background: color,
        opacity: 0.5,
      },
    }
    const noteCircle = Object.assign({}, styles.circle, {
      background: color,
      opacity: 0.5,
      margin: '-2px',
    })

    return (
      <div className="curve-fit-legenda">
        <div style={styles.circle} />
        <div>{t('insight.measurement')}</div>
        <div style={styles.circle}>
          <div style={noteCircle} />
        </div>
        <div>{t('insight.measurementWithNote')}</div>
        <div style={styles.line} />
        <div>{t('insight.modeledLevel')}</div>
        <div style={styles.square} />
        <div>{t('insight.levelCi')}</div>
      </div>
    )
  }
}

CurveFitLegenda.propTypes = {
  color: PropTypes.string,
}

export default withTranslation()(CurveFitLegenda)
