import { observable, reaction, computed } from 'mobx'
import User from './User'

export default class App {
  @observable init = true
  @observable user = null
  @observable access = false

  @computed
  get initializing() {
    return this.user.init || this.init
  }

  constructor(t) {
    this.t = t
    this.user = new User(this.t)

    reaction(
      () => this.user.roles,
      (roles) => {
        this.access =
          roles.includes('Caregiver') ||
          roles.includes('Admin') ||
          roles.includes('ContractAdmin')
      },
      { fireImmediately: true },
    )
    this.init = false
  }
}
