import React, { Fragment } from 'react'
import * as d3 from 'd3'
import { Box } from '@material-ui/core'
import './circularprogress.css'

function ProgressArc(props) {
  const { svgWidth, arcWidth, progressPercentage, colorIndicator } = props
  const svgHeight = svgWidth
  const arcOuterRadius = svgWidth / 2
  const arcInnerRadius = svgWidth / 2 - arcWidth
  const arcGenerator = d3
    .arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5)
  const progressArc = (value) =>
    arcGenerator({
      endAngle: 2 * Math.PI * value,
    })

  return (
    <div className="circular-progress-circle">
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path d={progressArc(1)} opacity="0.2" fill="gray" />
        </g>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path
            d={progressArc(progressPercentage / 100)}
            fill={colorIndicator}
          />
        </g>
      </svg>
    </div>
  )
}

export default function CurcularProgress({ values = [] }) {
  const svgWidth = 148
  const arcWidth = 8
  const chartsProperties = [
    { color: '#F7B92B', radius: svgWidth },
    { color: '#05B9AE', radius: Math.round(svgWidth * 0.84) },
    { color: '#76DDFB', radius: Math.round(svgWidth * 0.65) },
  ]

  if (!values.length) {
    return <Fragment />
  }

  return (
    <section className="circular-progress-wrapper">
      <Box className="circular-progress-chart">
        {values.map(({ value }, index) => (
          <ProgressArc
            key={index}
            svgWidth={chartsProperties[index].radius}
            arcWidth={arcWidth}
            progressPercentage={value}
            colorIndicator={chartsProperties[index].color}
          />
        ))}
      </Box>
      <div className="circular-progress-legend-list">
        {values.map(({ value, hint }, index) => (
          <div key={index} className="circular-progress-legend-list-item">
            <div
              className="circular-progress-legend-circle"
              style={{ backgroundColor: chartsProperties[index].color }}
            />
            <p className="circular-progress-legend-label">
              {`${value}%`}
              <span>{hint}</span>
            </p>
            <p className="circular-progress-legend-hint"></p>
          </div>
        ))}
      </div>
    </section>
  )
}
