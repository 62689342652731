import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Chip } from '@material-ui/core'
import { Trans, withTranslation } from 'react-i18next'
import { styled, withStyles } from '@material-ui/styles'

const styles = (theme) => ({
  countsWrapper: {
    display: 'flex',
    color: theme.palette.primary.darker,
    marginBottom: 50,
  },
  active: {
    fontSize: '2.125rem',
  },
  boxLabel: {
    fontStyle: 'italic',
    position: 'inherit',
  },
  circle: {
    width: 120,
    height: 120,
    marginLeft: 40,
    marginRight: 64,
    borderRadius: '50%',
    border: `6px solid ${theme.palette.primary.darker}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleCount: {
    color: 'inherit',
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.1,
  },
  circleLabel: {
    color: 'inherit',
    textAlign: 'center',
    lineHeight: 1.25,
  },
  chipRowsTitle: {
    color: theme.palette.primary.darkest,
    width: '100%',
    fontWeight: 700,
  },
  chipRows: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '600px',
    gap: '9px 13px',
  },
})
const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette?.primary?.darker,
  color: 'white',
}))
@observer
class SubscriptionCount extends Component {
  render() {
    const {
      t,
      user: { caregiverContracts: contracts, selectedContract },
      classes,
    } = this.props

    if (
      contracts &&
      !(
        contracts.some(
          (contract) => contract.totalSubscriptions || contract.subscriptions,
        ) && contracts.some((contract) => contract.activeSubscriptions)
      )
    ) {
      return <Fragment />
    }

    const currentContract = contracts.find(
      (contract) => contract._id === selectedContract,
    )

    const getCurrentContractCount = () => {
      const totalSubs =
        currentContract.totalSubscriptions || currentContract.subscriptions
      const activeSubs = currentContract.activeSubscriptions
      return typeof totalSubs === 'number' && typeof activeSubs === 'number'
        ? totalSubs - activeSubs
        : '-'
    }
    const currentContractGroups = currentContract?.caregiverGroups

    return (
      <div className={classes.countsWrapper}>
        <div>
          <div className={classes.circle}>
            <span className={classes.circleCount}>
              {getCurrentContractCount()}
            </span>
            <span className={classes.circleLabel}>
              {t('subscriptionCount.licencesAvailable')}
            </span>
          </div>
        </div>
        <div className={classes.chipRows}>
          <span className={classes.chipRowsTitle}>
            <Trans
              i18nKey="subscriptionCount.caregiverGroupTitle"
              count={currentContractGroups?.length}
            />
          </span>
          {currentContractGroups?.map(({ _id, name }) => (
            <div className={classes.singleCount} key={_id}>
              <StyledChip label={name} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

SubscriptionCount.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object,
  t: PropTypes.func,
}
const SubscriptionCountHOC = withStyles(styles)(SubscriptionCount)
export default withTranslation()(SubscriptionCountHOC)
