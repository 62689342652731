import { createTheme } from '@material-ui/core/styles'

const colorPalette = {
  darkGreenish: '#127263',
  darkerGreenish: '#223F38',
  darkerGreenish2: '#145545',
  darkerGreenish3: '#465559',
  lightGreenish: '#A4F1CB',
  mediumGrey: '#a9a9a9',
  violet: '#694AA2',
}

export default createTheme({
  palette: {
    primary: {
      main: colorPalette.darkGreenish,
      darker: colorPalette.darkerGreenish2,
      darkest: colorPalette.darkerGreenish3,
    },
    secondary: {
      main: colorPalette.lightGreenish,
    },
    background: {
      default: 'rgba(251, 251, 251, 0)',
    },
    custom: {
      violet: colorPalette.violet,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["'Lato', sans-serif"].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        background: 'linear-gradient(to right, #A4F1CB, #91E1D5)',
        borderRadius: '25px',
        boxShadow: 'none',
        height: '44px',
        fontWeight: 400,
        paddingLeft: '25px',
        paddingRight: '25px',
        color: '#223F38',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        background: 'transparent',
      },
    },
    MuiSnackbarContent: {
      root: {
        color: '#000',
      },
    },
  },
})
