import React, { Component } from 'react'
import * as d3 from 'd3'
import { XAxis, YAxis } from './Axis'
import PropTypes from 'prop-types'
import moment from 'moment'

const barChartPropTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.objectOf(PropTypes.number),
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  width: 600,
  height: 300,
  margin: { bottom: 25, left: 25 },
  data: [],
  colors: [
    'rgb(136,209,39)',
    'rgb(193,232,46)',
    'rgb(231,210,47)',
    'rgb(231,176,40)',
    'rgb(232,143,34)',
    'rgb(231,91,29)',
    'rgb(234,12,24)',
  ],
}

class BarChart extends Component {
  render() {
    const {
      data,
      width,
      height,
      margin,
      startDate,
      endDate,
      minValue,
      maxValue,
      colors,
    } = this.props
    const inner = { height: Math.max(0, height - margin.bottom) }

    const x = d3.scaleUtc().range([0, width])
    if (startDate && endDate) {
      x.domain([startDate, endDate.toDate()])
    } else {
      x.domain(d3.extent(data, (d) => d.date))
    }

    const y = d3.scaleLinear()
    if (typeof minValue === 'number' && maxValue) {
      y.domain([minValue, maxValue])
    } else {
      y.domain([0, d3.max(data, (d) => d.value)])
    }
    const rows = y.ticks(maxValue || null).length
    y.range([inner.height - inner.height / rows, 0])

    // plot a lines for every point
    const lines = data.map((d, i) => {
      return (
        <rect
          key={i}
          id={d.date.toString().replace(/[^a-zA-Z0-9]/g, '')}
          x={x(d.date)}
          y={y(d.value)}
          style={{ fill: colors[d.value] }}
          width={'2px'}
          height={inner.height / rows}
        />
      )
    })

    return (
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <XAxis
          ticks={x.ticks(d3.timeMonth.every(1))}
          scale={x}
          x={margin.left}
          y={inner.height}
          width={width}
          labelFormat={d3.timeFormat('%b `%y')}
        />

        <YAxis
          ticks={y.ticks(maxValue || null)}
          scale={y}
          x={0}
          y={margin.bottom}
          height={inner.height}
          width={width}
        />

        <g>{lines}</g>
      </svg>
    )
  }
}
BarChart.propTypes = barChartPropTypes
BarChart.defaultProps = defaultProps

export default BarChart
