import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import Settings from '../components/Settings'

@observer
class SettingsPage extends Component {
  render() {
    const { revalidateData } = this.props
    return <Settings revalidateData={revalidateData} />
  }
}

SettingsPage.propTypes = {
  admin: PropTypes.bool,
  go: PropTypes.func,
  revalidateData: PropTypes.func,
}

export default SettingsPage
