import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import {
  Search as SearchIcon,
  Email as EmailIcon,
  Add as AddIcon,
  Clear as ClearIcon,
} from '@material-ui/icons'
import { withStyles, styled } from '@material-ui/styles'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import _ from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import Navigator from '../Navigator'
import AlertDialog from '../Dialog'
import './users.css'

const StyledSearchFieldsWrapper = styled('div')({
  '& > div[class*=MuiTextField-root]': {
    border: '1px solid #127263',
    borderRadius: '20px',
    padding: '5px 13px',
    height: '44px',
  },
  position: 'static',
})

const StyledSearchField = withStyles({
  root: {
    '& + div[class*=MuiFormControl]': {
      marginLeft: '15px',
    },
  },
})(TextField)

const StyledSearchBirthdayField = withStyles({
  root: {
    '& p[class*=MuiFormHelperText]': {
      marginTop: '10px',
    },
  },
})(KeyboardDatePicker)

@observer
class Users extends Component {
  @observable open = false
  @observable title = null
  @observable text = null
  @observable actions = null

  formatDate(date) {
    return moment(new Date(date)).format('DD-MM-YYYY HH:mm')
  }

  formatDateOnly(date) {
    return moment(new Date(date)).format('DD-MM-YYYY')
  }

  @action.bound
  handleClose() {
    this.open = false
    this.title = null
    this.text = null
    this.actions = null
  }

  @action.bound
  resendInvite(email) {
    const { resendInvite, t } = this.props
    resendInvite(email)

    this.open = true
    this.title = t('table.resendEmail', { email })
    this.actions = [
      <Button key={1} onClick={this.handleClose} color="primary">
        {t('main.ok')}
      </Button>,
    ]
  }

  @action.bound
  confirmInactiveUser(user) {
    // inactive is the current state of the user
    const { t } = this.props

    this.open = true
    this.title = !user.active
      ? t('table.confirmActivatePatient')
      : t('table.confirmDeactivatePatient')
    this.actions = [
      <Button key={1} onClick={this.handleClose} color="primary">
        {t('main.cancel')}
      </Button>,
      // now we have to invert the inactive state, because we want th change it
      <Button key={2} onClick={this.invertActiveStateUser.bind(null, user)}>
        {t('main.yes')}
      </Button>,
    ]
  }

  @action.bound
  async invertActiveStateUser(user) {
    const { t } = this.props
    const userId = user._id
    const contractId = user.contractId
    const groupId = user.caregiverGroupId

    const {
      activeState,
      contractEndWarning,
      activeUntil,
    } = await this.props.setActivtyUser(
      userId,
      contractId,
      groupId,
      // request to invert the current state!
      !user.active,
    )
    const endDate = activeUntil
      ? moment(new Date(activeUntil)).format('DD-MM-YYYY')
      : null
    let popupText = ''
    // activeState is the state after the service call
    if (activeState) {
      // on return of setActivity user the user active
      popupText = t('table.setPatientActive')
    } else {
      popupText = t('table.setPatientInactive')
      if (endDate) {
        if (contractEndWarning) {
          popupText += ' ' + t('table.patienActiveUntilWarning', { endDate })
        } else {
          popupText += ' ' + t('table.patienActiveUntil', { endDate })
        }
      }
    }

    this.open = true
    this.title = activeState
      ? t('table.patientActive')
      : t('table.patientInActive')
    this.text = popupText
    this.actions = [
      <Button key={1} onClick={this.handleClose} color="primary">
        {t('main.ok')}
      </Button>,
    ]
  }

  @action.bound
  confirmDeleteUser(userId, contractId) {
    const { t } = this.props
    this.open = true
    this.title = t('table.removePatientWarning')
      this.actions = [
        <Button key={0} onClick={this.handleClose} color="primary">
          {t('main.cancel')}
        </Button>,
        <Button key={1} onClick={this.deleteUser.bind(null, userId, contractId)}>
          {t('main.ok')}
        </Button>,
      ]
  }

  @action.bound
  async deleteUser(userId, studyName) {
    const { deleteUser, t } = this.props
    const result = await deleteUser(userId, studyName)

    this.open = true
    this.title = t('table.deletePatient')
    this.actions = [
      <Button key={1} onClick={this.handleClose} color="primary">
        {t('main.ok')}
      </Button>,
    ]
    this.text =
      result.status === 'activeUser'
        ? t('table.removePatientError')
        : t('table.removePatientSuccess')
  }

  @action.bound
  confirmRemoveUserData(userId, studyName) {
    const { t } = this.props
    this.open = true
    this.title = t('table.removePatientDataWarning')
    this.actions = [
      <Button key={2} onClick={this.handleClose} color="primary">
        {t('main.cancel')}
      </Button>,
      <Button key={3} onClick={() => this.clearAllUserData(userId, studyName)}>
        {t('main.ok')}
      </Button>,
    ]
  }

  @action.bound
  async clearAllUserData(userId, studyName) {
    const { clearUserData, t } = this.props
    const result = await clearUserData(userId, studyName)

    this.open = true
    this.title = t('table.clearPatientData')
    this.actions = [
      <Button key={4} onClick={this.handleClose} color="primary">
        {t('main.ok')}
      </Button>,
    ]
    this.text =
      result?.status === 'success'
        ? t('table.removePatientDataSuccess')
        : t('table.removePatientDataError')
  }

  @action.bound
  handleInputChange(event) {
    this.props.userModel.searchPatientByParam(event.target.value)
  }

  @action.bound
  orderAndSortBy(sortBy) {
    this.props.userModel.sortAndOrderBy(sortBy)
  }

  @action.bound
  handleSetSeachBirthday(dateObject) {
    this.props.userModel.setSearchedBirthdayParam(dateObject)
  }

  render() {
    const {
      userModel,
      users,
      go,
      pageNumber,
      lastPage,
      navigator,
      openAddUser,
      t,
    } = this.props
    const {
      searchedProp,
      clearSearchPatientByParam,
      orderDirection,
      sortBy,
      searchedBirthday,
      validDate,
    } = userModel
    const linkStyle = {
      cursor: 'pointer',
    }

    const contractId = userModel.selectedContract

    return (
      <section className="users-table-wrapper">
        <div className="users-table-header">
          <div>
            <StyledSearchFieldsWrapper>
              <StyledSearchField
                placeholder={t('table.typeNameOrEmail')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchedProp.length > 0 && (
                        <IconButton
                          aria-label="clear searchfield button"
                          onClick={clearSearchPatientByParam}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={this.handleInputChange}
                value={searchedProp}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <StyledSearchBirthdayField
                  autoOk
                  placeholder={t('table.searchByBirthday')}
                  format="DD-MM-YYYY"
                  value={searchedBirthday}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  error={!validDate}
                  helperText={t('table.useCorrectFormat')}
                  onChange={this.handleSetSeachBirthday}
                />
              </MuiPickersUtilsProvider>
            </StyledSearchFieldsWrapper>
          </div>
          <Button variant="contained" onClick={openAddUser}>
            <AddIcon fontSize={'small'} />
            {t('table.addPatient')}
          </Button>
        </div>
        <div className="users-table">
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      direction={orderDirection}
                      active={sortBy === 'fullName'}
                      onClick={() => {
                        this.orderAndSortBy('fullName')
                      }}
                    >
                      {t('table.fullName')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderDirection}
                      active={sortBy === 'email'}
                      onClick={() => {
                        this.orderAndSortBy('email')
                      }}
                    >
                      {t('table.email')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderDirection}
                      active={sortBy === 'birthDay'}
                      onClick={() => {
                        this.orderAndSortBy('birthDay')
                      }}
                    >
                      {t('table.birthDay')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t('insight.code')}</TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderDirection}
                      active={sortBy === 'activatedOn'}
                      onClick={() => {
                        this.orderAndSortBy('activatedOn')
                      }}
                    >
                      {t('table.activatedOn')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderDirection}
                      active={sortBy === 'lastExperimentDate'}
                      onClick={() => {
                        this.orderAndSortBy('lastExperimentDate')
                      }}
                    >
                      {t('table.lastExperimentDate')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t('table.groupName')}</TableCell>
                  <TableCell>{t('table.forgotPassword')}</TableCell>
                  <TableCell>
                    {t('table.inactive')}
                    <Tooltip title={t('table.inactiveTooltip')}>
                      <IconButton aria-label="delete">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{t('table.activeUntil')}</TableCell>
                  <TableCell>{t('table.delete')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() =>
                        go(
                          `/insight?userId=${user._id}&contractId=${contractId}`,
                        )
                      }
                    >
                      <span
                        style={linkStyle}
                        className={`users-table-item-hover ${'underline'}`}
                      >
                        {user.fullName}
                      </span>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() =>
                        go(
                          `/insight?userId=${user._id}&contractId=${contractId}`,
                        )
                      }
                    >
                      <span
                        style={linkStyle}
                        className={`users-table-item-hover ${'underline'}`}
                      >
                        {user.email}
                      </span>
                    </TableCell>
                    <TableCell>
                      {user.birthDay ? this.formatDateOnly(user.birthDay) : '-'}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        go(
                          `/insight?userId=${user._id}&contractId=${contractId}`,
                        )
                      }
                    >
                      <span
                        style={linkStyle}
                        className={`users-table-item-hover ${'underline'}`}
                      >
                        {user.code}
                      </span>
                    </TableCell>
                    <TableCell>
                      {user.createdAt ? this.formatDate(user.createdAt) : '-'}
                    </TableCell>
                    <TableCell>
                      {user.lastExperimentDate
                        ? this.formatDateOnly(user.lastExperimentDate)
                        : '-'}
                    </TableCell>
                    <TableCell>{user.caregiverGroupName || '-'}</TableCell>
                    <TableCell>
                      <EmailIcon
                        onClick={() => {
                          this.resendInvite(user.email)
                        }}
                        className="users-table-item-hover"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={!user.active}
                        onChange={this.confirmInactiveUser.bind(null, user)}
                        className="users-table-item-hover"
                      />
                    </TableCell>
                    <TableCell>
                      {user.activeUntil
                        ? this.formatDateOnly(user.activeUntil)
                        : '-'}
                    </TableCell>
                    <TableCell>
                      <DeleteForeverIcon
                        onClick={() => {
                          this.confirmDeleteUser(
                            `auth0|${user._id}`,
                            user.contractId,
                          )
                        }}
                        className="users-table-item-hover"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        {(lastPage && pageNumber === 1) || !users.length ? null : (
          <Navigator
            next={!lastPage ? () => navigator('next') : null}
            last={!lastPage ? () => navigator('last') : null}
            prev={pageNumber !== 1 ? () => navigator('prev') : null}
            first={pageNumber !== 1 ? () => navigator('first') : null}
            date={pageNumber}
          />
        )}
        <AlertDialog
          text={this.text}
          title={this.title}
          open={this.open}
          actions={this.actions}
        />
      </section>
    )
  }
}

export default withTranslation()(Users)
