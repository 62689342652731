import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

ReactGA.initialize(process.env.GA_MEASUREMENT_ID)

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],
  enabled: location.hostname !== 'localhost',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
})

ReactDOM.render(<App />, document.getElementById('app'))

module.hot.accept()
