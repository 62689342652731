import React, { Component } from 'react'
import { observer } from 'mobx-react'

import ContractsComponent from '../components/Contracts'

@observer
class Contracts extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { contracts, admin, go } = this.props
    return <ContractsComponent contracts={contracts} admin={admin} go={go} />
  }
}
export default Contracts
