import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { withTranslation } from 'react-i18next'

class NoAccess extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <Typography>{t('noAccess.heading')}</Typography>
      </div>
    )
  }
}

export default withTranslation()(NoAccess)
