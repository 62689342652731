import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Snackbar from '../Snackbar'
import request from '../../utils/request'

const styles = {
  buttonsWrapper: {
    padding: '10px',
  },
  heading: {
    marginBottom: '15px',
  },
}

@observer
class ResetConsents extends Component {
  @observable requestLoading = false
  @observable confirmationDialog = false
  @observable dialogTitle = ''
  @observable dialogCallback = () => {}
  @observable error = ''
  @observable timer = null

  @action.bound
  openConfirmationDialog = (title, callback) => {
    this.confirmationDialog = true
    this.dialogTitle = title
    this.dialogCallback = callback
  }

  @action.bound
  closeConfirmationDialog = () => {
    this.confirmationDialog = false
    this.dialogTitle = ''
    this.dialogCallback = () => {}
  }

  @action.bound
  clearAppConsents = async (consentName) => {
    const { t } = this.props

    try {
      this.requestLoading = true
      await request('sherpa-admin', 'resetAppConsent', {
        body: {
          consentName,
        },
        method: 'PATCH',
        authHeader: true,
      })
      if (result.status !== 'success') {
        throw new Error(t('main.genericError'))
      }
    } catch (error) {
      this.error = error.message || error
      this.clearError()
    } finally {
      this.requestLoading = false
    }

    this.confirmationDialog = false
  }

  @action.bound
  clearDashboardConsents = async (consentName) => {
    const { t } = this.props

    try {
      this.requestLoading = true
      const result = await request('sherpa-admin', 'resetDashboardConsent', {
        body: {
          consentName,
        },
        method: 'PATCH',
        authHeader: true,
      })
      if (result.status !== 'success') {
        throw new Error(t('main.genericError'))
      }
    } catch (error) {
      this.error = error.message || error
      this.clearError()
    } finally {
      this.requestLoading = false
    }

    this.confirmationDialog = false
  }

  @action.bound
  clearError = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => (this.error = ''), 5000)
  }

  render() {
    const { admin, go, t } = this.props
    let errorSnackBar = null

    if (!admin) {
      go('/')
      return <></>
    }

    if (this.error) {
      errorSnackBar = <Snackbar message={this.error} />
    }

    return (
      <>
        <div style={styles.buttonsWrapper}>
          <Typography variant="h6" style={styles.heading}>
            {t('resetConsents.mobilePermission')}
          </Typography>
          <Button
            style={{ marginBottom: '10px', marginRight: '10px' }}
            variant="contained"
            onClick={() =>
              this.openConfirmationDialog(
                t('resetConsents.resetConsentsWarning'),
                () => this.clearAppConsents('privacyStatement'),
              )
            }
          >
            {t('resetConsents.resetPrivacyStatement')}
          </Button>
          <Button
            style={{ marginBottom: '10px', marginRight: '10px' }}
            variant="contained"
            onClick={() =>
              this.openConfirmationDialog(
                t('resetConsents.resetUserStatementWarning'),
                () => this.clearAppConsents('userStatement'),
              )
            }
          >
            {t('resetConsents.resetUserStatement')}
          </Button>
        </div>
        <div style={styles.buttonsWrapper}>
          <Typography variant="h6" style={styles.heading}>
            {t('resetConsents.dashboardPermission')}
          </Typography>

          <Button
            style={{ marginBottom: '10px', marginRight: '10px' }}
            variant="contained"
            onClick={() =>
              this.openConfirmationDialog(
                t('resetConsents.resetDashboardConsentsWarning'),
                () => this.clearDashboardConsents('eula'),
              )
            }
          >
            {t('resetConsents.resetEula')}
          </Button>
          <Button
            style={{ marginBottom: '10px', marginRight: '10px' }}
            variant="contained"
            onClick={() =>
              this.openConfirmationDialog(
                t('resetConsents.resetDashboardUserStatementWarning'),
                () => this.clearDashboardConsents('privacyStatement'),
              )
            }
          >
            {t('resetConsents.resetPrivacyStatement')}
          </Button>
          <Dialog
            open={this.confirmationDialog}
            onClose={this.closeConfirmationDialog}
          >
            <DialogTitle>{this.dialogTitle}</DialogTitle>

            <DialogActions>
              <Button onClick={this.closeConfirmationDialog} color="primary">
                {t('main.no')}
              </Button>
              <Button
                onClick={this.dialogCallback}
                color="primary"
                disabled={this.requestLoading}
                autoFocus
              >
                {t('main.yes')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {errorSnackBar}
      </>
    )
  }
}

ResetConsents.propTypes = {
  admin: PropTypes.bool,
  go: PropTypes.func,
}

export default withTranslation()(ResetConsents)
