import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import { withStyles } from '@material-ui/styles'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
  Typography,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import InquiriesPanel from '../InquiriesPanel'
import Snackbar from '../Snackbar'

const styles = (theme) => ({
  form: {
    maxWidth: '408px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(4, 3),
    width: '100%',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  heading: {
    color: '#485465',
    fontWeight: 700,
  },
  switchRow: {
    margin: theme.spacing(1.5, 0),
    alignItems: 'center',
  },
  testsHeading: {
    fontWeight: 700,
    fontSize: '14px',
    margin: theme.spacing(3, 0, 0),
  },
  submitButton: {
    margin: theme.spacing(2, 0, 0),
  },
  extraMargin: {
    margin: theme.spacing(1, 0),
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputsTextStyles: {
    fontSize: 14,
    '&:placeholder': {
      color: '#7E7E7E',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  formLabel: {
    color: '#000',
    fontWeight: 100,
    flex: '1 0 auto',
  },
  frequencySelect: {
    borderRadius: 20,
    border: '1px solid #066158',
    color: '#066158',
    padding: theme.spacing(0, 1.5),
    backgroundColor: 'transparent',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    marginRight: '1rem',
  },
  icon: {
    fill: '#066158',
  },
  subscriptionsWarning: {
    fontSize: '12px',
    marginTop: '7px',
    textAlign: 'center',
  },
})

@observer
class AddUser extends Component {
  @observable email = ''
  @observable contractId = null
  @observable language = 'nl'
  @observable inquiries = []
  @observable defaultInquiries
  @observable caregiverGroupId
  @observable caregiverGroupsDropdown = []

  @computed
  get canAddUser() {
    if (!this.contractId) {
      return false
    }

    const { contracts } = this.props
    const contract = contracts.find(({ _id }) => _id === this.contractId)

    if (!contract) {
      return false
    }

    return (
      contract.submittedSubscriptions <
      (contract.subscriptions || contract.totalSubscriptions)
    )
  }

  constructor(props) {
    super(props)
    const { t } = this.props
    this.contractId = this.props.contracts[0]._id
    this.defaultInquiries = this.props.contracts[0].inquiries
    this.frequencies = [
      { value: '1 day', label: t('addUser.daily') },
      { value: '1 week', label: t('addUser.weekly') },
      { value: '2 weeks', label: t('addUser.every2Weeks') },
      { value: '4 weeks', label: t('addUser.every4Weeks') },
      { value: '13 weeks', label: t('addUser.every13Weeks') },
    ]
    this.caregiverGroupsDropdown = this.props.contracts[0]?.caregiverGroups
    this.caregiverGroupId = this.props.contracts[0]?.caregiverGroups[0]?.id
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  @action.bound
  changeContract = (name) => (event) => {
    this[name] = event.target.value
    const { contracts } = this.props
    this.inquiries = null
    this.defaultInquiries = null
    this.caregiverGroupsDropdown =
      contracts.find(({ _id }) => _id === event.target.value)
        ?.caregiverGroups || []
    this.caregiverGroupId = this.caregiverGroupsDropdown[0]?.id

    _.map(contracts, (contract) => {
      if (contract._id === event.target.value) {
        this.defaultInquiries = contract.inquiries
        this.inquiries = this.prepareInquiries(contract.inquiries)
      }
    })
  }

  prepareInquiries = (inquiries) => {
    return inquiries.map(({ _id, schedule }) => {

      return {
        _id,
        ...schedule
      }
    })
  }

  render() {
    const {
      loading,
      contracts,
      addUser,
      error,
      isOpen,
      handleClose,
      classes,
      t,
    } = this.props
    let selectedContract = null
    let selectGroups = null
    let errorMessage = null

    // display select box with atleast 2 or more options
    if (contracts.length > 1) {
      selectedContract = (
        <Select
          fullWidth
          value={this.contractId}
          onChange={this.changeContract('contractId')}
          name="contractId"
          className={[
            classes.extraMargin,
            classes.inputsTextStyles,
            classes.italic,
          ].join(' ')}
        >
          {_.map(contracts, (contract) => {
            return (
              <MenuItem key={contract._id} value={contract._id}>
                {contract.title}
              </MenuItem>
            )
          })}
        </Select>
      )
    }

    if (this.caregiverGroupsDropdown.length > 0) {
      selectGroups = (
        <Select
          fullWidth
          value={this.caregiverGroupId}
          onChange={this.handleChange('caregiverGroupId')}
          name="caregiverGroupId"
          className={[
            classes.extraMargin,
            classes.inputsTextStyles,
            classes.italic,
          ].join(' ')}
        >
          {this.caregiverGroupsDropdown.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name || '----'}
              </MenuItem>
            )
          })}
        </Select>
      )
    }

    if (error) {
      errorMessage = <Snackbar message={error} />
    }
    return (
      <Fragment>
        {errorMessage}
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.form}>
            <Typography className={classes.heading} variant="h5">
              {t('table.addPatient')}
            </Typography>
            <TextField
              fullWidth
              id="email"
              label={t('addUser.patientEmail')}
              value={this.email}
              onChange={this.handleChange('email')}
              className={[classes.extraMargin, classes.inputsTextStyles].join(
                ' ',
              )}
              type="email"
            />
            {selectedContract}
            {selectGroups}
            <Select
              fullWidth
              value={this.language}
              onChange={this.handleChange('language')}
              name="language"
              className={[classes.extraMargin, classes.inputsTextStyles].join(
                ' ',
              )}
            >
              <MenuItem key="nl" value="nl">
                {t('addUser.dutch')}
              </MenuItem>
              <MenuItem key="en" value="en">
                {t('addUser.english')}
              </MenuItem>
              <MenuItem key="de" value="de">
                {t('addUser.german')}
              </MenuItem>
              <MenuItem key="fr" value="fr">
                {t('addUser.french')}
              </MenuItem>
            </Select>

            <Fragment>
              <Typography className={classes.testsHeading}>
                {t('addUser.tests')}
              </Typography>
              <InquiriesPanel
                inquiries={this.inquiries}
                defaultInquiries={this.defaultInquiries}
                reset={true}
              />
            </Fragment>

            <div className={classes.buttonWrapper}>
              <Button
                onClick={() =>
                  addUser(
                    this.email,
                    this.contractId,
                    this.language,
                    this.inquiries || this.defaultInquiries,
                    this.caregiverGroupId,
                  )
                }
                variant="contained"
                className={classes.submitButton}
                disabled={!this.canAddUser}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  t('table.addPatient')
                )}
              </Button>
            </div>
            <div className={classes.subscriptionsWarning}>
              {this.canAddUser || t('addUser.subscriptionLimitExceeded')}
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

AddUser.propTypes = {
  contracts: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
}

const AddUserHOC = withStyles(styles)(AddUser)
export default withTranslation()(AddUserHOC)
