import { action, observable } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import request from '../utils/request'
import EditContractComponent from '../components/EditContract'
import {
  FIXED_FEE_CONTRACT_TYPE,
  RECURRENT_CONTRACT_TYPE,
} from '../models/Types'

@observer
class EditContract extends Component {
  @observable loading = false
  @observable error = null
  @observable contract = null
  @observable editContract = false

  constructor(props) {
    super()
    this.contract = props.contract
    if (props.contract) {
      this.editContract = true
    }
  }

  @action.bound
  addContract(contract) {
    const {
      _id,
      client,
      title,
      subscriptions,
      startDate,
      endDate,
      contractType,
      activationPeriod,
      renewalPeriod,
      inquiries,
      submittedSubscriptions,
      active,
      firstname,
      lastname,
      email,
      language,
      insertion,
    } = contract
    const { getData, go, t } = this.props
    this.loading = true
    this.error = null

    // check is client is filled in
    if (client === null || client === '') {
      this.error = t('editContract.requiredCompany')
      // check is title is filled in
    } else if (title === null || title === '') {
      this.error = t('editContract.requiredName')
      // check is subscriptions is filled in
    } else if (!subscriptions || subscriptions === 0) {
      this.error = t('editContract.requiredSubscription')
      // check is inquiries are picked
    } else if (inquiries.length === 0) {
      this.error = t('editContract.requiredInquiry')
    } else if (submittedSubscriptions > subscriptions) {
      this.error = t('editContract.subscriptionError', {
        submittedSubscriptions,
        subscriptions,
      })
    } else if (
      email === null ||
      firstname === null ||
      lastname === null ||
      language === null ||
      email === '' ||
      firstname === '' ||
      lastname === '' ||
      language === ''
    ) {
      this.error = t('editContract.admin.error')
    }

    // check startDate is smaller the endDate
    if (moment(endDate).diff(startDate, 'minutes') <= 0) {
      this.error = t('editContract.startDateError')
      // check is activationPeriod is filled in
    } else if (contractType === RECURRENT_CONTRACT_TYPE) {
      if (!activationPeriod || activationPeriod === 0) {
        this.error = t('editContract.requiredActivation')
        // check is renewalPeriod is filled in
      } else if (!renewalPeriod || renewalPeriod === 0) {
        this.error = t('editContract.requiredRenewal')
      }
    }

    if (!this.error) {
      // create contract
      request('sherpa-admin', 'contract', {
        method: 'POST',
        body: {
          _id,
          title,
          client,
          license: true,
          subscriptions,
          inquiries,
          contractStartDate: startDate,
          contractEndDate: endDate,
          contractType:
            contractType === FIXED_FEE_CONTRACT_TYPE
              ? 'FIXED_FEE'
              : 'RECURRENT',
          activationPeriod,
          renewalPeriod,
          active,
          firstname,
          lastname,
          email,
          language,
          insertion,
        },
      })
        .then((data) => {
          if (data.message) {
            this.error = data.message
          } else {
            // create scheduler collection studyUsers in monitor db
            if (!_id) {
              request('scheduler', 'add', {
                method: 'POST',
                body: {
                  name: `monitoring - Study users ${title}`,
                  interval: '1 days',
                  startDate: new Date(),
                  url: `${process.env.SERVER_URL}monitor/db/sherpa/studyUsers`,
                  json: {
                    studyId: data.studyId,
                  },
                },
              })
            }
            getData()
            go('/contracts')
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    } else {
      this.loading = false
    }
  }

  render() {
    return (
      <EditContractComponent
        addContract={this.addContract}
        currentContract={this.contract}
        loading={this.loading}
        error={this.error}
        editContract={this.editContract}
      />
    )
  }
}

export default withTranslation()(EditContract)
