import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action, computed } from 'mobx'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { withTranslation } from 'react-i18next'

import validate from '../../config/validate'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

const styles = (theme) => ({
  extraMargin: {
    margin: theme.spacing(1, 0),
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputsTextStyles: {
    fontSize: 14,
    '&:placeholder': {
      color: '#7E7E7E',
    },
  },
  nextElement: {
    marginTop: 15,
  },
})

@observer
class AddContractUser extends Component {
  @observable firstname = ''
  @observable insertion = ''
  @observable lastname = ''
  @observable email = ''
  @observable language = 'nl'
  @observable role = ''
  @computed get validEmail() {
    return validate.validateEmail(this.email)
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  render() {
    const { open, close, addContractUser, classes, t } = this.props

    return (
      <>
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle>{t('caregiverGroup.addUser')}</DialogTitle>
          <DialogContent>
            <InputLabel id="firstname">
              {t('editContract.admin.firstname')}
            </InputLabel>
            <TextField
              id="firstname"
              type="firstname"
              value={this.firstname}
              onChange={this.handleChange('firstname')}
              fullWidth
            />

            <InputLabel id="insertion" className={classes.nextElement}>
              {t('editContract.admin.insertion')}
            </InputLabel>
            <TextField
              id="insertion"
              type="insertion"
              value={this.insertion}
              onChange={this.handleChange('insertion')}
              fullWidth
            />

            <InputLabel id="lastname" className={classes.nextElement}>
              {t('editContract.admin.lastname')}
            </InputLabel>
            <TextField
              id="lastname"
              type="lastname"
              value={this.lastname}
              onChange={this.handleChange('lastname')}
              fullWidth
            />

            <InputLabel id="email" className={classes.nextElement}>
              {t('contracts.caregiverEmail')}*
            </InputLabel>
            <TextField
              id="email"
              type="email"
              value={this.email}
              onChange={this.handleChange('email')}
              fullWidth
            />

            <InputLabel id="language" className={classes.nextElement}>
              {t('contracts.language')}
            </InputLabel>
            <Select
              fullWidth
              value={this.language}
              onChange={this.handleChange('language')}
              id="language"
              name="language"
              className={[classes.extraMargin, classes.inputsTextStyles].join(
                ' ',
              )}
            >
              <MenuItem key="nl" value="nl">
                {t('addUser.dutch')}
              </MenuItem>
              <MenuItem key="en" value="en">
                {t('addUser.english')}
              </MenuItem>
              <MenuItem key="de" value="de">
                {t('addUser.german')}
              </MenuItem>
            </Select>

            <InputLabel id="role" className={classes.nextElement}>
              {t('caregiverGroup.role')}*
            </InputLabel>
            <Select
              fullWidth
              value={this.role}
              onChange={this.handleChange('role')}
              id="role"
              name="role"
              className={[classes.extraMargin, classes.inputsTextStyles].join(
                ' ',
              )}
            >
              <MenuItem key="caregiver" value="caregiver">
                {t('caregiverGroup.caregiver')}
              </MenuItem>
              <MenuItem key="secretary" value="secretary">
                {t('caregiverGroup.coordinator')}
              </MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                addContractUser(
                  this.email,
                  this.language,
                  this.role,
                  this.firstname,
                  this.insertion,
                  this.lastname,
                )
              }}
              color="primary"
              disabled={!this.validEmail || !this.role.length}
            >
              {t('contracts.add')}
            </Button>
            <Button
              onClick={() => {
                close()
              }}
            >
              {t('main.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

AddContractUser.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  addContractUser: PropTypes.func,
  classes: PropTypes.object,
}

const AddContractUserHOC = withStyles(styles)(AddContractUser)
export default withTranslation()(AddContractUserHOC)
