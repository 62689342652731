import React, { Component } from 'react'
import * as d3 from 'd3'
import { XAxis, YAxis } from './Axis'
import moment from 'moment'
import './index.scss'
import PropTypes from 'prop-types'

const curveFitChartPropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  line: PropTypes.array,
  margin: PropTypes.objectOf(PropTypes.number),
  points: PropTypes.array,
  unit: PropTypes.string,
  unitYAs: PropTypes.string,
  color: PropTypes.string,
  area: PropTypes.array,
}

const defaultProps = {
  width: 600,
  height: 300,
  margin: { top: 20, right: 20, bottom: 30, left: 50 },
  unit: null,
  color: 'black',
}

const styles = {
  area: {
    opacity: 0.5,
  },
  line: {
    fill: 'none',
    strokeWidth: 2,
  },
  circle: {
    r: 4,
    strokeWidth: 2,
  },
}

class CurveFitChart extends Component {
  render() {
    const {
      width,
      height,
      line = [],
      points,
      margin,
      unitYAs,
      color,
      unit,
      area = [],
    } = this.props
    const inner = {
      width: Math.max(0, width - margin.left),
      height: Math.max(0, height - margin.top - margin.bottom),
    }
    // random id so multiple linechart can be displayed on 1 page
    const graphicId = Math.random().toString(36).substring(7)

    const x = d3.scaleUtc().domain(d3.extent(points, (d) => d.date))

    // set time till end of the month
    x.domain([x.domain()[0], moment(x.domain()[1]).endOf('month')._d])
    x.range([margin.left, width - margin.right])

    // get min and max value from the points and area data
    // calc the different between min and max value and add/subsctract 10% on both ends
    const minValues = [d3.min(points, (d) => d.value)]
    if (area.length > 0) minValues.push(d3.min(area, (d) => d.lower))
    const maxValues = [d3.max(points, (d) => d.value)]
    if (area.length > 0) maxValues.push(d3.max(area, (d) => d.upper))
    const minValue = Math.min(...minValues)
    const maxValue = Math.max(...maxValues)
    const diff = (maxValue - minValue) * 0.1

    const y = d3
      .scaleLinear()
      .domain([minValue - diff, maxValue + diff])
      .range([height - margin.bottom, margin.top])

    // create line
    const calcLine = d3
      .line()
      .defined((d) => !isNaN(d.value))
      .x((d) => x(d.date))
      .y((d) => y(d.value))

    // create area
    const calcArea = d3
      .area()
      .x((d) => x(d.date))
      .y0((d) => y(d.upper))
      .y1((d) => y(d.lower))

    // plot a circle for every point
    const circles = points.map((d) => {
      if (d.value) {
        const id = d.date.toString().replace(/[^a-zA-Z0-9]/g, '') + graphicId
        const date = moment(d.date).format('D MMMM YYYY HH:mm')
        const unitValue = unit === null ? '' : unit
        const value = `${Math.round(d.value)} ${unitValue}`
        const colorFill = d.text
          ? color.slice(0, color.length - 1) +
            ',0.5' +
            color.slice(color.length - 1)
          : 'white'
        return (
          <circle
            key={id}
            id={id}
            cx={x(d.date)}
            cy={y(d.value)}
            style={styles.circle}
            fill={colorFill}
            stroke={color}
            onMouseOver={(e) => {
              const text = d.text ? `<br/>${d.text}` : ''
              d3.select(`#${id}`).style('fill', color)

              // display tooltip
              d3.select(`#tooltip${graphicId}`).html(
                `${date}<br/><b>${value}</b>${text}`,
              )
              const tooltip = document.getElementById(`tooltip${graphicId}`)
              d3.select(`#tooltip${graphicId}`)
                .style('left', `${e.clientX - 40}px`)
                .style(
                  'top',
                  `${e.clientY + window.scrollY - 10 - tooltip.offsetHeight}px`,
                )
                .style('opacity', 0.9)
              d3.select(`#tooltip-dot${graphicId}`)
                .style('left', `${e.clientX - 36}px`)
                .style(
                  'top',
                  `${e.clientY + window.scrollY - 6 - tooltip.offsetHeight}px`,
                )
                .style('opacity', 1)
            }}
            onMouseOut={() => {
              d3.select(`#${id}`).style('fill', colorFill)

              // remove tooltip
              d3.select(`#tooltip${graphicId}`).style('opacity', 0)
              d3.select(`#tooltip-dot${graphicId}`).style('opacity', 0)
            }}
          />
        )
      }
    })

    return (
      <div>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          id={graphicId}
        >
          <XAxis
            ticks={x.ticks(d3.timeMonth.every(1))}
            scale={x}
            x={0}
            y={inner.height + margin.top}
            width={width}
            labelFormat={d3.timeFormat('%b `%y')}
          />

          <YAxis
            ticks={y.ticks()}
            scale={y}
            x={margin.left}
            y={margin.top}
            height={inner.height}
            width={inner.width}
            text={unitYAs}
          />

          <g>
            <path d={calcArea(area)} style={styles.area} fill={color} />
            <path d={calcLine(line)} style={styles.line} stroke={color} />
            {circles}
          </g>
        </svg>

        <div
          className="tooltip"
          id={`tooltip${graphicId}`}
          style={{ opacity: 0 }}
        />
        <div
          className="tooltip-dot"
          id={`tooltip-dot${graphicId}`}
          style={{ opacity: 0, backgroundColor: color }}
        />
      </div>
    )
  }
}

CurveFitChart.propTypes = curveFitChartPropTypes
CurveFitChart.defaultProps = defaultProps

export default CurveFitChart
