import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import { withStyles } from '@material-ui/styles'
import { Button, Modal, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import InquiriesPanel from '../InquiriesPanel'

const styles = (theme) => ({
  form: {
    maxWidth: '440px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(4, 3),
    width: '100%',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  heading: {
    color: '#485465',
    fontWeight: 700,
  },
  submitButton: {
    margin: theme.spacing(2, 0, 0),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})

@observer
class EditInquiries extends Component {
  @observable canEditInquiries = true

  constructor(props) {
    super(props)
  }

  render() {
    const {
      updateInquiries,
      cancelInquiries,
      inquiries,
      defaultInquiries,
      isOpen,
      classes,
      t,
    } = this.props
    return (
      <Modal
        open={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.form}>
          <Typography className={classes.heading} variant="h5">
            {t('tests.editTestProtocol')}
          </Typography>
          <Fragment>
            <InquiriesPanel
              inquiries={inquiries}
              defaultInquiries={defaultInquiries}
            />
          </Fragment>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={cancelInquiries}
              variant="contained"
              className={classes.submitButton}
            >
              {t('main.cancel')}
            </Button>
            <Button
              onClick={updateInquiries}
              variant="contained"
              className={classes.submitButton}
              disabled={!this.canEditInquiries}
            >
              {t('main.save')}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

EditInquiries.propTypes = {
  updateInquiries: PropTypes.func.isRequired,
  cancelInquiries: PropTypes.func.isRequired,
  inquiries: PropTypes.object,
  defaultInquiries: PropTypes.object.isRequired,
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
}

const EditInquiriesHOC = withStyles(styles)(EditInquiries)
export default withTranslation()(EditInquiriesHOC)
