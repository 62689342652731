import React from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'

const propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prev: PropTypes.func,
  next: PropTypes.func,
  loading: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
  children: PropTypes.node,
  first: PropTypes.func,
  last: PropTypes.func,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
}

const styles = {
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}

export default class Navigator extends React.Component {
  render() {
    const {
      date,
      prev,
      next,
      first,
      last,
      nextDisabled,
      prevDisabled,
      loading,
      fullWidth,
      children,
      style = {},
    } = this.props

    const dataDisplay = {
      lineHeight: '72px',
      textAlign: 'center',
    }

    if (fullWidth) dataDisplay.width = '100%'

    return (
      <div className="navigatorComponent">
        {children}
        <div
          className="navigatorControls"
          style={Object.assign(style, styles.main)}
        >
          {!first ? (
            <IconButton style={styles.hidden}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={first}
              style={styles.buttonStyle}
              disabled={loading}
            >
              <FirstPageIcon />
            </IconButton>
          )}
          {!prev ? (
            <IconButton style={styles.hidden}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={prev}
              style={styles.buttonStyle}
              disabled={prevDisabled || loading}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <div style={dataDisplay}>{<div>{date}</div>}</div>
          {!next ? (
            <IconButton style={styles.hidden}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={next}
              style={styles.buttonStyle}
              disabled={nextDisabled || loading}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
          {!last ? (
            <IconButton style={styles.hidden}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={last}
              style={styles.buttonStyle}
              disabled={loading}
            >
              <LastPageIcon />
            </IconButton>
          )}
        </div>
      </div>
    )
  }
}
Navigator.propTypes = propTypes
