import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import moment from 'moment'
import request from '../utils/request'
import { getDefaultLanguage } from '../utils/defaultLanguage'

const colors = [
  'rgb(136,209,39)',
  'rgb(193,232,46)',
  'rgb(231,210,47)',
  'rgb(231,176,40)',
  'rgb(232,143,34)',
  'rgb(231,91,29)',
  'rgb(234,12,24)',
]

const colorsLong = [
  'rgb(136,209,39)',
  'rgb(156,221,60)',
  'rgb(193,232,46)',
  'rgb(222,244,50)',
  'rgb(239,245,52)',
  'rgb(255,229,51)',
  'rgb(231,210,47)',
  'rgb(231,176,40)',
  'rgb(232,143,34)',
  'rgb(231,91,29)',
  'rgb(234,12,24)',
]

export default class Insight {
  @observable summaryInit = true
  @observable userInit = true
  @observable startDate = moment().subtract(11, 'months').startOf('month')

  @computed get init() {
    return this.summaryInit && this.userInit
  }

  @observable userId

  constructor(userId, contractId) {
    this.userId = userId
    this.contractId = contractId
    this.getData(userId, contractId)
  }

  @action.bound
  getData(userId, contractId) {
    const data = request('sherpa-caregiver', 'appUser', {
      method: 'GET',
      query: {
        userId,
        contractId,
      }
    }).then((data) => {
      this.walking = data.walking
      this.sdmt = data.sdmt
      this.notes = data.notes
      this.profile = data.profile
      this.inquiries = data.inquiries || []
      this.versions = data.versions
      this.questions = data.questions
      this.startDate = this.getStartDate(data.firstExperimentDate)
      this.userInit = false
    })
  }

  getStartDate(firstExpDate) {
    // if the date of the first finished experiment is within last 12 months,
    // set it as a startDate for the charts and graphs
    const firstExperimentDate = firstExpDate || null
    if (!firstExperimentDate) {
      return this.startDate
    }

    return moment(firstExperimentDate).isAfter(this.startDate)
      ? moment(firstExperimentDate)
      : this.startDate
  }

  @action.bound
  getExperiment(date, months, type) {
    const line = []
    const points = []
    const area = []
    const startDate = new Date(moment(date)).getTime()
    const endDate = new Date(moment(date).add(months, 'months')).getTime()
    const experiment = type === 'sdmt' ? this.sdmt : this.walking
    const warning = experiment.warning

    // adding min and max date to display in
    points.push({ date: new Date(startDate) })
    points.push({ date: new Date(endDate - 86400000) }) // extract one day

    if (!this.userInit) {
      experiment.points.map((p) => {
        const pointDate = new Date(p.timestamp).getTime()
        if (pointDate >= startDate && pointDate < endDate) {
          // add note value to the point in the graphic
          let text = null
          this.notes.map((n) => {
            if (moment(p.timestamp).isSame(n.timestamp, 'day')) text = n.value
          })
          points.push({ date: new Date(p.timestamp), value: p.value, text })
        }
      })

      if (experiment.data) {
        experiment.data.map((d) => {
          line.push({ date: new Date(d.date), value: d.level })
          area.push({
            date: new Date(d.date),
            upper: d['95percentUpperBound'],
            lower: d['95percentLowerBound'],
          })
        })
      }
    }
    return { line, points, area, warning }
  }

  @action.bound
  async getTestsSummary(startDate, months) {
    let endTime = moment(startDate)
      .startOf('month')
      .add(months, 'months')
      .toISOString()
    const startTime = moment(startDate).toISOString()

    if (moment(endTime).isAfter(moment())) {
      endTime = moment().toISOString()
    }

    this.summaryInit = true

    try {
      const result = (await request('sherpa-insight', 'experimentPercentage', {
          query: {
            startDate: startTime,
            endDate: endTime,
            patientId: this.userId
          },
        }))
      return {
        sdmt: Math.round(result?.sdmt || 0),
        walking: Math.round(result?.walking || 0),
        pro: Math.round(result?.answers || 0),
      }
    } finally {
      this.summaryInit = false
    }
  }

  getNotes(date, months) {
    const notes = []
    const startDateInt = new Date(date).getTime()
    const endDateInt = new Date(
      moment(date).add(months, 'month').endOf('month'),
    ).getTime()

    this.notes.map((note) => {
      const datetime = new Date(note.timestamp).getTime()
      if (datetime >= startDateInt && datetime < endDateInt) notes.push(note)
    })
    return notes
  }

  getQuestions(date, months) {
    const currentLanguage = 'title_' + getDefaultLanguage
    const questions = []
    const startDateInt = new Date(date).getTime()
    const endDateInt = new Date(
      moment(date).add(months, 'month').endOf('month'),
    ).getTime()
    this.questions.map((q) => {
      const questionTitle = q[currentLanguage] ? q[currentLanguage] : q.title
      const observations = []
      q.observations.map((o) => {
        const datetime = new Date(o.date).getTime()
        if (datetime >= startDateInt && datetime < endDateInt) {
          observations.push({ date: new Date(o.date), value: o.value })
        }
      })
      const title = getDefaultLanguage === 'en' ? q.title.en_US : q.title.nl_NL
      questions.push({
        observations,
        title: title,
        minValue: q?.options?.min,
        maxValue: q?.options?.max,
        colors: q?.options?.max > 6 ? colorsLong : colors,
      })
    })
    return questions
  }

  getAverageTestsCompletion(points) {
    const summed = points.reduce(
      (result, { scheduledTests, completedTests }) => {
        return [result[0] + scheduledTests, result[1] + completedTests]
      },
      [0, 0],
    )

    return summed[1] / summed[0]
  }
}
