import { EventEmitter } from 'events'
import { isTokenExpired, getIdAndEmail, getParam } from './jwtHelper'
import auth0 from 'auth0-js'
import { observable, action } from 'mobx'

class Auth extends EventEmitter {
  @observable loading = false
  @observable profile = {}
  @observable _id = null
  @observable email = null

  constructor() {
    super()

    this.auth0 = new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      responseType: 'token id_token code',
      redirectUri: window.location.href,
    })

    this.login = this.login.bind(this)

    this.getIdAndEmail()
  }

  async login() {
    this.loading = true
    if (
      window.location.hash &&
      !window.location.hash.includes('access_token') &&
      window.location.hash.split('#')[1]
    ) {
      localStorage.setItem('saved_path', window.location.hash.split('#')[1])
      localStorage.setItem('from_login', true)
    }
    return new Promise((resolve, reject) => {
      try {
        this.auth0.authorize({
          scope: 'openid profile offline_access roles',
          fromDashboard: true,
        })
      } catch (e) {
        reject(e)
      }

      resolve()
    })
  }

  logout() {
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_at')

    const url =
      'https://' +
      process.env.AUTH0_DOMAIN +
      '/v2/logout?' +
      'returnTo=' +
      encodeURIComponent(window.location.href) +
      '&client_id=' +
      process.env.AUTH0_CLIENTID
    window.location.replace(url)
  }

  @action.bound
  parseHash(hash) {
    this.loading = true
    this.auth0.parseHash({ hash }, (err, authResult) => {
      if (err || authResult.error) {
        this.loading = false
      } else {
        this.setAuthTokens(null, authResult)
      }
    })
  }

  @action.bound
  setAuthTokens(err, authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      const savedPath = localStorage.getItem('saved_path')
      window.location.hash = savedPath || ''
      this.expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime(),
      )

      localStorage.setItem('access_token', authResult.accessToken)
      localStorage.setItem('id_token', authResult.idToken)
      localStorage.setItem('expires_at', this.expiresAt)
      localStorage.setItem('refresh_token', authResult.refreshToken)

      this.getIdAndEmail()

      this.loading = false
    }
  }

  @action.bound
  loggedIn() {
    const token = localStorage.getItem('id_token')
    return !!token && !isTokenExpired(token)
  }

  @action.bound
  setLoading(value) {
    this.loading = value
  }

  @action.bound
  getIdAndEmail() {
    if (localStorage.id_token) {
      const data = getIdAndEmail(localStorage.id_token)
      this._id = data.userId.split('|')[1]
      this.email = data.email
    }
  }

  getJwtParam(param) {
    if (localStorage.id_token) {
      return getParam(localStorage.id_token, param)
    }
    return null
  }
}

export default new Auth()
