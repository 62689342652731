import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import html2canvas from 'html2canvas'
import moment from 'moment'
import jsPDF from 'jspdf'
import { withTranslation } from 'react-i18next'
import icon_ce from './images/ce_marking.png'
import icon_udi from './images/udi-symbol.png'

import Warning from './Warning'
import BarChart from '../BarChart'
import CurveFitChart from '../CurveFitChart'
import Navigator from '../Navigator'
import Snackbar from '../Snackbar'
import Loading from '../Loading'
import CircularChart from '../CircularProgress'
import CurveFitLegenda from '../CurveFitLegenda'
import EditInquiries from '../EditInquiries'
import './index.scss'

@observer
class Insight extends Component {
  @observable exportLoading = false
  @observable showInquiriesModal = false
  @observable inquiries = []
  @observable error = ''

  constructor(props) {
    super(props)
    const { t, inquiries } = this.props
    this.inquiries = inquiries
    this.inquiriesFrequencies = {
      '1 day': t('addUser.daily'),
      '1 week': t('addUser.weekly'),
      '2 weeks': t('addUser.every2Weeks'),
      '4 weeks': t('addUser.every4Weeks'),
      '13 weeks': t('addUser.every13Weeks'),
    }
  }

  exportPdf(date) {
    const documentToExport = document.getElementById('insight-page')
    this.exportLoading = true
    html2canvas(documentToExport, {
      ignoreElements: (element) =>
        element === documentToExport.querySelector('.download-pdf'),
      width: 1289,
      height: 3692,
      windowHeight: 869,
      windowWidth: 1257,
      scale: 1.0625,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')

        const imgWidth = 210 - 10
        const pageHeight = 295
        const imgHeight = (canvas.height * imgWidth) / canvas.width - 10
        let heightLeft = imgHeight
        const doc = new jsPDF('p', 'mm') // [297, 210]
        let position = 5

        doc.addImage(
          imgData,
          'PNG',
          5,
          position,
          imgWidth,
          imgHeight - 10,
          'alias',
        )
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(
            imgData,
            'PNG',
            5,
            position,
            imgWidth,
            imgHeight,
            'alias',
          )
          heightLeft -= pageHeight
        }
        doc.save(`insight-${date.toLowerCase().replace(/ /g, '_')}.pdf`)
      })
      .catch((error) => {
        this.error = error
      })
      .finally(() => (this.exportLoading = false))
  }

  @action.bound
  handleInquiriesClose() {
    const { updateInquiries } = this.props
    this.showInquiriesModal = false
    updateInquiries(this.inquiries)
  }

  @action.bound
  handleInquiriesCancel() {
    this.showInquiriesModal = false
  }

  getInquirySelectValue(inquiryId) {
    const inquiry = this.inquiries.find(({ _id }) => _id === inquiryId)
    if (inquiry && inquiry.interval) {
      const result = this.inquiriesFrequencies[inquiry.interval]
      return result || this.inquiriesFrequencies['1 week']
    } else {
      return '---'
    }
  }

  render() {
    const {
      prev,
      next,
      date,
      walking,
      sdmt,
      testsSummary,
      notes = [],
      questions = [],
      profile = {},
      versions = {},
      loading,
      startDate,
      dialog,
      openDialog,
      closeDialog,
      defaultInquiries,
      timeRange,
      t,
    } = this.props
    if (loading) return <Loading />
    if (!profile.name) {
      return <div>{t('insight.noData')}</div>
    }
    const widhtGraphics = 700
    const barCharts = []
    questions.map((q, index) => {
      barCharts.push(
        <div key={index} className="blockQuestions">
          <h4 className="blockTitle">{q.title}</h4>
          <BarChart
            data={q.observations}
            width={700}
            height={q.maxValue * 20}
            minValue={q.minValue}
            maxValue={q.maxValue}
            startDate={new Date(startDate)}
            colors={q.colors}
            endDate={moment(startDate).add(timeRange, 'months').endOf('month')}
          />
        </div>,
      )
    })

    const sdmtText = [
      t('insight.sdmtLine1'),
      <br key="sdmtLine11" />,
      <br key="sdmtLine12" />,
      t('insight.sdmtLine2'),
      <br key="sdmtLine21" />,
      <br key="sdmtLine22" />,
      t('insight.sdmtLine3'),
    ]

    const twomwtText = [
      t('insight.twoMWTLine1'),
      <br key="2mwtLine11" />,
      <br key="2mwtLine12" />,
      t('insight.twoMWTLine2'),
      <br key="2mwtLine21" />,
      <br key="2mwtLine22" />,
      t('insight.twoMWTLine3'),
    ]

    return (
      <Fragment>
        {this.error && <Snackbar message={this.error} />}
        <div className="page" id="insight-page">
          <Grid container spacing={3}>
            <Grid item xs={4} id="profile">
              <div className="header">
                <div className="title">
                  {t('insight.title', { name: profile.name })}
                </div>
              </div>
              <table className="profile">
                <tbody>
                  <tr>
                    <td>
                      <b>{t('insight.code')}</b>
                    </td>
                    <td className="tabledata">{profile.code}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('insight.dateOfBirth')}</b>
                    </td>
                    <td className="tabledata">
                      {profile.birthday
                        ? moment(profile.birthday).format('DD MMMM YYYY')
                        : t('main.unknown')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('insight.msSherpaSince')}</b>
                    </td>
                    <td className="tabledata">
                      {profile.activatedOn
                        ? moment(profile.activatedOn).format('DD MMMM YYYY')
                        : t('main.unknown')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('insight.contract')}</b>
                    </td>
                    <td className="tabledata">{profile.contractName}</td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <Grid item xs={4} id="inquiries">
              <Grid container spacing={3}>
                <Grid item xs={5} id="inq_content">
                  <div className="header">
                    <div className="title">{t('tests.testProtocol')}</div>
                  </div>
                  <table className="profile">
                    <tbody>
                      {defaultInquiries.map(({ _id, name }) => (
                        <tr key={_id}>
                          <td> {name || _id} </td>
                          <td className="tabledata">
                            {this.getInquirySelectValue(_id)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={1} id="inq_edit">
                  <EditIcon
                    className="editTestProtocolIcon"
                    onClick={() => (this.showInquiriesModal = true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="navigator">
            <Navigator
              date={date}
              next={next}
              prev={prev}
              color={'rgb(27, 158, 119)'}
              fullWidth={false}
            />
            <Button
              onClick={() => this.exportPdf(date)}
              className="download-pdf"
              disabled={this.exportLoading}
            >
              {this.exportLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('insight.downloadPdf')
              )}
            </Button>
          </div>
          <CircularChart
            values={[
              {
                value: testsSummary?.sdmt || 0,
                hint: t('insight.sdmtHint'),
              },
              {
                value: testsSummary?.walking || 0,
                hint: t('insight.tmwtHint'),
              },
              {
                value: testsSummary?.pro || 0,
                hint: t('insight.questionnaireHint'),
              },
            ]}
          />
          <div className="insight-container">
            <div className="insight-line-charts">
              <div className="block">
                <h4>2MWT</h4>
                <CurveFitLegenda color={'rgb(255, 193, 7)'} />
                <InfoIcon
                  onClick={() => openDialog('2MWT')}
                  className="infoIcon"
                />
                <CurveFitChart
                  line={walking.line}
                  points={walking.points}
                  area={walking.area}
                  unitYAs="meters"
                  unit="meter"
                  color="rgb(255,193,7)"
                  width={widhtGraphics}
                />
                {walking.warning ? <Warning width={widhtGraphics} /> : null}
              </div>
              <div className="block">
                <h4>{t('insight.sdmtHeading')}</h4>
                <CurveFitLegenda color={'rgb(164, 241, 203)'} />
                <InfoIcon
                  onClick={() => openDialog('SDMT')}
                  className="infoIcon"
                />
                <CurveFitChart
                  line={sdmt.line}
                  points={sdmt.points}
                  area={sdmt.area}
                  unitYAs={t('insight.points')}
                  unit="score"
                  color="rgb(164,241,203)"
                  width={widhtGraphics}
                />
                {sdmt.warning ? <Warning width={widhtGraphics} /> : null}
              </div>

              {barCharts}
            </div>
            <div className="insight-notes">
              {notes.map((note, index) => (
                <div key={index} className="note">
                  <div className="noteTitle">
                    {moment(note.timestamp).format('DD MMM YYYY')}
                  </div>
                  <div>{note.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Dialog open={dialog !== null}>
          <DialogTitle>
            {dialog === '2MWT'
              ? t('insight.explanationTmwt')
              : t('insight.explanationSdmt')}
          </DialogTitle>
          <DialogContent>
            {dialog === '2MWT' ? twomwtText : sdmtText}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog()}>{t('main.close')}</Button>
          </DialogActions>
        </Dialog>
        <EditInquiries
          defaultInquiries={defaultInquiries}
          inquiries={this.inquiries}
          updateInquiries={this.handleInquiriesClose}
          cancelInquiries={this.handleInquiriesCancel}
          isOpen={this.showInquiriesModal}
        />
      </Fragment>
    )
  }
}

export default withTranslation()(Insight)
