import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action, computed } from 'mobx'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const styles = (theme) => ({
  extraMargin: {
    margin: theme.spacing(1, 0),
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputsTextStyles: {
    fontSize: 14,
    '&:placeholder': {
      color: '#7E7E7E',
    },
  },
  nextElement: {
    marginTop: 15,
  },
})

@observer
class SwitchUserRole extends Component {
  @observable role = ''

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  render() {
    const {
      open,
      close,
      switchContractUserRole,
      userId,
      userEmail,
      classes,
      t,
    } = this.props

    return (
      <>
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle>{t('caregiverGroup.switchRole')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{userEmail}</DialogContentText>

            <InputLabel id="role" className={classes.nextElement}>
              {t('caregiverGroup.role')}
            </InputLabel>
            <Select
              fullWidth
              value={this.role}
              onChange={this.handleChange('role')}
              id="role"
              name="role"
              className={[classes.extraMargin, classes.inputsTextStyles].join(
                ' ',
              )}
            >
              <MenuItem key="caregiver" value="caregiver">
                {t('caregiverGroup.caregiver')}
              </MenuItem>
              <MenuItem key="secretary" value="secretary">
                {t('caregiverGroup.coordinator')}
              </MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                switchContractUserRole(userId, this.role)
              }}
              color="primary"
            >
              {t('caregiverGroup.switch')}
            </Button>
            <Button
              onClick={() => {
                close()
              }}
            >
              {t('main.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

SwitchUserRole.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  addContractUser: PropTypes.func,
  classes: PropTypes.object,
}

const SwitchUserRoleHOC = withStyles(styles)(SwitchUserRole)
export default withTranslation()(SwitchUserRoleHOC)
