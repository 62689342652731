import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import _ from 'lodash'
import { sortVersionObject } from '../../utils/helpers'
import { withTranslation } from 'react-i18next'

import './devices.css'

@observer
class DevicesVersions extends Component {
  render() {
    const { appVersions = {}, appVersionsTotal = 0, t } = this.props

    return (
      <section className="devices-wrapper devices-versions">
        <Typography variant="h6" className="device-heading">
          {t('devicesPage.msSherpaVersions')}
        </Typography>
        <div className="devices-green-heading">
          {!Object.keys(appVersions).length ? (
            <div className="devices-row-item">
              <Typography>-</Typography>
            </div>
          ) : (
            <>
              <div className="devices-row-item">
                <Typography>{t('devicesPage.total')}</Typography>
              </div>
              <div className="devices-row-item">
                <Typography>{appVersionsTotal || '-'}</Typography>
              </div>
            </>
          )}
        </div>
        {Object.keys(sortVersionObject(appVersions)).map(
          (versionName, index) => (
            <div key={index} className="device-row">
              <div className="devices-row-item">
                <Typography>{versionName}</Typography>
              </div>
              <div className="devices-row-item">
                <Typography>{appVersions[versionName]}</Typography>
              </div>
            </div>
          ),
        )}
      </section>
    )
  }
}

DevicesVersions.propTypes = {
  appVersions: PropTypes.object,
  appVersionsTotal: PropTypes.number,
}

export default withTranslation()(DevicesVersions)
