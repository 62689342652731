import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/styles'
import { Select, MenuItem, Typography, OutlinedInput } from '@material-ui/core'
import DevicesTable from '../components/Devices/devicesTable'
import DeviceVersions from '../components/Devices/devicesVersions'
import DevicesSystems from '../components/Devices/devicesSystems'
import { withTranslation } from 'react-i18next'

const FilterTopBar = styled('div')({
  backgroundColor: '#145545',
  padding: '20px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
})

const StyledTablesWrapper = styled('div')({
  display: 'flex',
})

const StyledSelect = styled(Select)(({ theme, disabled }) => {
  return {
    minWidth: 186,
    maxWidth: 250,
    marginLeft: 20,
    border: 'none',

    '& > div': {
      background: '#fff',
      border: `none`,
      padding: '10px 40px 10px 20px!important',
      borderRadius: 16,
      fontSize: 14,
      color: '#145545',
      // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      '&:focus': {
        borderRadius: 16,
        background: '#fff',
        border: `none`,
      },
    },
    '& > svg': {
      color: 'white',
      right: 10,
      display: disabled ? 'none' : 'inline-block',
    },

    '& fieldset[class*=MuiOutlinedInput]': {
      border: 'none',
      color: '#000',
    },
  }
})

@observer
class DevicesPage extends Component {
  constructor(props) {
    super(props)

    props.userModel.selectNewContractForVersions(
      props.userModel.selectedVersionContract,
    )
  }

  render() {
    const {
      go,
      admin,
      userModel: { contracts = [] },
      userModel,
      t,
    } = this.props

    if (!admin) {
      go('/')
      return <></>
    }

    const handleChange = (event) => {
      const {
        target: { value },
      } = event
      userModel.selectNewContractForVersions(value)
    }

    return (
      <div>
        <FilterTopBar>
          <Typography variant="h5">{t('devicesPage.filterBy')}</Typography>
          <StyledSelect
            fullWidth
            value={userModel.selectedVersionContract}
            onChange={handleChange}
            name="filterByContract"
            input={<OutlinedInput />}
          >
            {contracts.map(({ title, _id }, index) => (
              <MenuItem key={index} value={_id}>
                {title}
              </MenuItem>
            ))}
          </StyledSelect>
        </FilterTopBar>
        <StyledTablesWrapper>
          <DeviceVersions
            appVersions={userModel.appVersions}
            appVersionsTotal={userModel.appVersionsTotal}
          />
          <DevicesSystems systemVersions={userModel.systemVersions} />
        </StyledTablesWrapper>
        <DevicesTable
          userModel={userModel}
          devicesRecords={userModel.usersVersionsList}
        />
      </div>
    )
  }
}

DevicesPage.propTypes = {
  go: PropTypes.func,
  userModel: PropTypes.object,
}

export default withTranslation()(DevicesPage)
