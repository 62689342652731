import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import { withStyles } from '@material-ui/styles'
import InputLabel from '@material-ui/core/InputLabel'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

const styles = (theme) => ({
  extraMargin: {
    margin: theme.spacing(1, 0),
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputsTextStyles: {
    fontSize: 14,
    '&:placeholder': {
      color: '#7E7E7E',
    },
  },
  nextElement: {
    marginTop: 15,
  },
})

@observer
class AddCaregiverGroup extends Component {
  @observable groupName = ''

  constructor(props) {
    super(props)
    const { t } = this.props
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  render() {
    const { open, close, addCaregiverGroup, classes, t } = this.props

    return (
      <>
        <Dialog open={open}>
          <DialogTitle>{t('contracts.addCaregiverGroup')}</DialogTitle>
          <DialogContent>
            <InputLabel id="groupName">
              {t('contracts.caregiverGroupName')}
            </InputLabel>
            <TextField
              id="groupName"
              type="text"
              value={this.groupName}
              onChange={this.handleChange('groupName')}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                addCaregiverGroup(this.groupName)
              }}
              color="primary"
              disabled={this.groupName === ''} // check if name is empty
            >
              {t('contracts.add')}
            </Button>
            <Button
              onClick={() => {
                close()
              }}
            >
              {t('main.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

AddCaregiverGroup.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  addCaregiverGroup: PropTypes.func,
  classes: PropTypes.object,
}

const AddCaregiverGroupHOC = withStyles(styles)(AddCaregiverGroup)
export default withTranslation()(AddCaregiverGroupHOC)
