import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { OutlinedInput, Select, FormControl, MenuItem } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { styled } from '@material-ui/styles'

const styles = {
  contractSelectWrapper: {
    display: 'flex',
    marginBottom: 20,
  },
}

const StyledSelect = styled(Select)(({ theme, disabled }) => {
  return {
    minWidth: 186,
    maxWidth: 250,
    marginBottom: 20,
    border: 'none',

    '& > div': {
      background: theme.palette?.custom?.violet,
      border: `2px solid ${theme.palette?.custom?.violet}`,
      padding: '10px 40px 10px 20px!important',
      borderRadius: 6,
      fontSize: 14,
      color: 'white',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      '&:focus': {
        borderRadius: 6,
        background: theme.palette?.custom?.violet,
        border: '2px solid black',
      },
    },
    '& > svg': {
      color: 'white',
      right: 10,
      display: disabled ? 'none' : 'inline-block',
    },

    '& fieldset[class*=MuiOutlinedInput]': {
      border: 'none',
    },
  }
})

@observer
class ContractSelect extends Component {
  render() {
    const {
      t,
      user: { caregiverContracts: contracts },
      user,
    } = this.props

    const handleChange = (event) => {
      const {
        target: { value },
      } = event
      user.selectNewContract(value)
    }

    const isOnlyOneContract = contracts && contracts.slice().length === 1

    return (
      <div style={styles.contractSelectWrapper}>
        <FormControl sx={{ m: 1 }}>
          <StyledSelect
            labelId="contract-name-label"
            id="contract-name"
            fullWidth
            value={user.selectedContract}
            onChange={handleChange}
            disabled={isOnlyOneContract}
            input={<OutlinedInput />}
          >
            {contracts.map(({ title, _id }, index) => (
              <MenuItem key={index} value={_id}>
                {title}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </div>
    )
  }
}

ContractSelect.propTypes = {
  user: PropTypes.object.isRequired,
}

export default withTranslation()(ContractSelect)
