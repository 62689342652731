import { observable, action, computed, autorun, reaction } from 'mobx'
import auth0 from '../services/auth0'
import querystring from 'qs'

class Router {
  @observable page = '/'
  @observable params = null
  @observable user = null
  @observable firstInit = true
  @observable saved_path = ''

  @computed
  get url() {
    const page = this.page
    const params = this.params
    let url = '/#'
    if (page[0] !== '/') url += '/'
    url += page
    if (params && Object.keys(params).length > 0) {
      url += '?' + querystring.stringify(params)
    }
    return url
  }

  constructor(props) {
    this.savedPath = localStorage.getItem('saved_path')
    this.page =
      this.savedPath ||
      (!window.location.hash.includes('access_token') &&
        window.location.hash.split('#')[1]) ||
      '/'
    this.params = null
    this.user = props.user

    if (window.location.hash.includes('access_token')) {
      auth0.parseHash(window.location.hash)
    } else {
      this.go(this.page, this.params)
    }

    reaction(
      () => this.user.init,
      (loading) => {
        if (!loading) {
          if (!this.user.eula || !this.user.privacyStatement) {
            !this.user.eula
              ? this.go('/acceptEula')
              : this.go('/acceptPrivacyStatement')
          } else {
            if (this.firstInit) {
              if (this.user.caregiver && !this.user.contractAdmin) {
                this.savedPath && this.go(this.page)
              } else {
                this.go('/contracts')
              }

              this.clearSavedPath()
              if (localStorage.getItem('from_login')) {
                localStorage.removeItem('from_login')
              }
              this.firstInit = false
            }
          }
        }
      },
    )

    autorun(() => history.pushState({}, '', this.url)) // reflect URL in browser
  }

  @action.bound
  go(page) {
    let params = null
    if (page && page.split('?').length > 1) {
      params = querystring.parse(page.split('?')[1] || '')
      page = page.match(/\/?([^?]*)/)[0]
    }
    this.params = params
    this.page = page
  }

  clearSavedPath = () => {
    if (
      this.savedPath &&
      !window.location.hash.includes('access_token') &&
      !localStorage.getItem('from_login')
    ) {
      localStorage.removeItem('saved_path')
      this.savedPath = ''
    }
  }
}
export default Router
