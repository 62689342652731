import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, SnackbarContent } from '@material-ui/core'

function SnackbarComponent({ message }) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={true}
        style={{ background: '#000' }}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          style={{ color: 'white' }}
          message={<span>{message}</span>}
        />
      </Snackbar>
    </div>
  )
}

SnackbarComponent.propTypes = {
  message: PropTypes.string.isRequired,
}

export default SnackbarComponent
