import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import { withStyles } from '@material-ui/styles'
import {
  FormLabel,
  Select,
  MenuItem,
  FormGroup,
  Switch,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import InfoIcon from '../InfoIcon'

const styles = (theme) => ({
  switchRow: {
    margin: theme.spacing(1.5, 0),
    alignItems: 'center',
  },
  formLabel: {
    color: '#000',
    fontWeight: 100,
    flex: '1 0 auto',
  },
  frequencySelect: {
    borderRadius: 20,
    border: '1px solid #066158',
    color: '#066158',
    padding: theme.spacing(0, 1.5),
    backgroundColor: 'transparent',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    marginRight: '1rem',
  },
  icon: {
    fill: '#066158',
  },
})

@observer
class InquiriesPanel extends Component {
  @computed
  get pickInquiries() {
    const { defaultInquiries } = this.props
    const inquiries = []
    _.map(inquiries, (inquiryId) => {
      const inquiry = _.find(defaultInquiries, { _id: inquiryId })
      inquiries.push(inquiry)
    })
    return inquiries
  }

  constructor(props) {
    super(props)
    const { t, reset } = this.props
    this.frequencies = [
      { value: '1 day', label: t('addUser.daily') },
      { value: '1 week', label: t('addUser.weekly') },
      { value: '2 weeks', label: t('addUser.every2Weeks') },
      { value: '4 weeks', label: t('addUser.every4Weeks') },
      { value: '13 weeks', label: t('addUser.every13Weeks') },
    ]
    if (reset) {
      this.resetInquiries()
    }
  }

  // refill the inquiries from the defaultInquiries
  resetInquiries = () => {
    const { inquiries, defaultInquiries } = this.props
    inquiries.length = 0
    defaultInquiries.map(({ _id }) => {
      inquiries.push(this.createFromDefault(_id))
    })
  }

  createFromDefault(inquiryId) {
    const { defaultInquiries } = this.props
    const defaultInq = defaultInquiries.find(({ _id }) => _id === inquiryId)
    const matchingInterval = this.frequencies.find(
      ({ value }) => value === defaultInq.schedule.interval,
    )
    return {
      _id: inquiryId,
      interval: matchingInterval ? matchingInterval.value : '1 week',
      availableFrom: defaultInq.schedule.availableFrom,
      expireAt: defaultInq.schedule.expireAt,
      notificationTime: defaultInq.schedule.notificationTime,
      pushNotification: true,
    }
  }

  // the property parameter is read-only on the parent (it is an observable), so may not be reassigned
  refillInquiries = (newInquiries) => {
    const { inquiries } = this.props
    inquiries.length = 0
    if (newInquiries) {
      newInquiries.forEach((element) => inquiries.push(element))
    }
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  @action.bound
  changeInquiryFrequency(inquiryId, interval) {
    const { inquiries } = this.props
    const inquiry = inquiries.find(({ _id }) => _id === inquiryId)
    const updatedInquiries = inquiries.filter(({ _id }) => _id !== inquiryId)
    const newInquiry = {
      _id: inquiryId,
      interval: interval,
      availableFrom: inquiry.availableFrom,
      expireAt: inquiry.expireAt,
      notificationTime: inquiry.notificationTime,
      pushNotification: inquiry.pushNotification,
    }
    updatedInquiries.push(newInquiry)
    this.refillInquiries(updatedInquiries)
  }

  inquiryChecked(inquiryId) {
    const { inquiries } = this.props
    return inquiries.some(({ _id }) => _id === inquiryId)
  }

  @action.bound
  changeInquiry(inquiryId) {
    const { inquiries } = this.props
    if (this.inquiryChecked(inquiryId)) {
      const filteredInquiries = inquiries.filter(({ _id }) => _id !== inquiryId)
      this.refillInquiries(filteredInquiries)
    } else {
      inquiries.push(this.createFromDefault(inquiryId))
    }
  }

  getInquirySelectValue(inquiryId) {
    const { inquiries } = this.props
    return inquiries.find((inq) => inq._id === inquiryId).interval
  }

  render() {
    const { defaultInquiries, classes, t } = this.props
    const tooltipExperimentInfo = t('addUser.experimentTooltip')
    const tooltipQuestionnaireInfo = t('addUser.questionnaireTooltip')
    return (
      <Fragment>
        {defaultInquiries.map(({ _id, name }) => (
          <FormGroup key={_id} row className={classes.switchRow}>
            <FormLabel className={classes.formLabel}>{name}</FormLabel>
            {this.inquiryChecked(_id) && (
              <Select
                value={this.getInquirySelectValue(_id)}
                className={classes.frequencySelect}
                onChange={({ target: { value } }) => {
                  this.changeInquiryFrequency(_id, value)
                }}
                name="selectedFrequency"
                disableUnderline
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                {this.frequencies.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}

            <Switch
              checked={this.inquiryChecked(_id)}
              onChange={this.changeInquiry.bind(null, _id)}
              name={_id}
              color="primary"
            />
            {_id !== '64a6a0602359e35814ef08fc' ? (
              <InfoIcon
                text={
                  name === 'questionnaire'
                    ? tooltipQuestionnaireInfo
                    : tooltipExperimentInfo
                }
              />
            ) : (
              <div style={{ width: 24 }} />
            )}
          </FormGroup>
        ))}
      </Fragment>
    )
  }
}

InquiriesPanel.propTypes = {
  inquiries: PropTypes.object,
  defaultInquiries: PropTypes.object,
  reset: PropTypes.bool,
  classes: PropTypes.object,
}

const InquiriesPanelHOC = withStyles(styles)(InquiriesPanel)
export default withTranslation()(InquiriesPanelHOC)
