import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { sortVersionObject } from '../../utils/helpers'

import './devices.css'

@observer
class DevicesSystems extends Component {
  render() {
    const { systemVersions = {}, t } = this.props

    return (
      <section className="devices-wrapper devices-systems">
        <Typography variant="h6" className="device-heading">
          {t('devicesPage.operatingSystem')}
        </Typography>
        <div className="devices-small-tables-wrapper">
          <div className="devices-small-table">
            <div className="devices-green-heading">
              <div className="devices-row-item">
                <Typography>Android</Typography>
              </div>
              <div className="devices-row-item">
                <Typography>{systemVersions.androidTotal || '-'}</Typography>
              </div>
            </div>
            {Object.keys(sortVersionObject(systemVersions.android || {})).map(
              (versionName, index) => (
                <div key={index} className="device-row">
                  <div className="devices-row-item">
                    <Typography>{versionName}</Typography>
                  </div>
                  <div className="devices-row-item">
                    <Typography>
                      {systemVersions.android[versionName]}
                    </Typography>
                  </div>
                </div>
              ),
            )}
          </div>
          <div className="devices-small-table">
            <div className="devices-green-heading">
              <div className="devices-row-item">
                <Typography>iOS</Typography>
              </div>
              <div className="devices-row-item">
                <Typography>{systemVersions.iosTotal || '-'}</Typography>
              </div>
            </div>
            {Object.keys(sortVersionObject(systemVersions.ios || {})).map(
              (versionName, index) => (
                <div key={index} className="device-row">
                  <div className="devices-row-item">
                    <Typography>{versionName}</Typography>
                  </div>
                  <div className="devices-row-item">
                    <Typography>{systemVersions.ios[versionName]}</Typography>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </section>
    )
  }
}

DevicesSystems.propTypes = {
  admin: PropTypes.bool,
  go: PropTypes.func,
}

export default withTranslation()(DevicesSystems)
