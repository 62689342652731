import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import './support.scss'

class Support extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="support">
        <p>
          {t('support.heading1')}{' '}
          <a href="mailto:support@mssherpa.nl">support@mssherpa.nl</a>{' '}
        </p>
        <p>
          <a
            href={t('support.manualAndInstructionsLink')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('support.manualAndInstructions')}
          </a>
        </p>
        <p>
          <a
            href={t('support.articlesLink')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('support.articles')}
          </a>
        </p>
      </div>
    )
  }
}

export default withTranslation()(Support)
