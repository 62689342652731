import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
  progress: {
    marginLeft: '50%',
    marginTop: '25%',
  },
})

function CircularIndeterminate(props) {
  const { classes } = props
  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  )
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CircularIndeterminate)
