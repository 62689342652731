import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, computed, action, toJS, reaction } from 'mobx'
import {
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryLegend,
  VictoryTooltip,
} from 'victory'
import { withTranslation } from 'react-i18next'

import request from '../../utils/request'
import './ContractInsightsUsersChart.scss'

@observer
class ContractInisghtsUsersChart extends Component {
  @observable usersCountData = []
  @observable domainY = [0, 1001]
  @observable loading = true
  @observable subscriptionsTreshold = null
  @computed get showThresholdLine() {
    return (
      this.subscriptionsTreshold &&
      this.domainY[1] >= this.subscriptionsTreshold * 0.7
    )
  }

  @computed get noData() {
    return (this.usersCountData || []).some((data) => !data.totalCount)
  }

  @action.bound
  async getData() {
    const studyIds = {}

    if (this.props.selectedStudyIds.length) {
      studyIds['studyIds[]'] = this.props.selectedStudyIds
    }

    const { data, subscriptionsTreshold } = await request(
      'sherpa-graph',
      'getUsers',
      {
        query: {
          ...studyIds,
          ...this.props.query,
        },
      },
    )

    this.usersCountData = data
    this.subscriptionsTreshold = subscriptionsTreshold
  }

  constructor(props) {
    super(props)

    reaction(
      () =>
        [
          this.props.period,
          this.props.dataBackShift,
          ...this.props.selectedStudyIds,
        ].join('|'),
      () => {
        this.loading = true
        this.getData()
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.usersCountData,
      () => {
        this.loading = false

        if (this.noData) {
          this.domainY = [
            0,
            this.usersCountData[this.usersCountData.length - 1].totalCount ||
              100,
          ]
          return
        }

        const maxTotal = this.usersCountData[this.usersCountData.length - 1]
          .totalCount
        const offset = Math.min(Math.ceil(maxTotal * 0.01), 5)
        const minY = Math.max(this.usersCountData[0].activeCount - offset, 0)
        const maxY = maxTotal + offset
        this.domainY = [minY, maxY]
      },
    )

    reaction(
      () => this.loading,
      () => this.props.setLoading(this.loading),
      { fireImmediately: true },
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className="usersChartComponent">
        <VictoryChart
          className="usersChart"
          theme={VictoryTheme.material}
          style={{ data: { paddingTop: '20px', float: 'left' } }}
          domainPadding={{ x: 10, y: 30 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum: { childName, totalCount, activeCount } }) => {
                if (childName === 'threshold') {
                  return t('usersChart.totalNum', {
                    number: this.subscriptionsTreshold,
                  })
                }

                return childName === 'total'
                  ? t('usersChart.totalNum', { number: totalCount })
                  : t('usersChart.activeNum', { number: activeCount })
              }}
              labelComponent={<VictoryTooltip />}
            />
          }
        >
          <VictoryLegend
            x={160}
            y={30}
            title={t('usersChart.accountsOverview')}
            centerTitle
            orientation="horizontal"
            gutter={20}
            style={{ title: { fontSize: 17 } }}
            data={[]}
          />

          {this.showThresholdLine ? (
            <VictoryLine
              y={() => this.subscriptionsTreshold}
              name="threshold"
              style={{ data: { stroke: '#223F38', strokeWidth: 2 } }}
            />
          ) : null}

          <VictoryAxis
            standalone={false}
            tickLabelComponent={
              <VictoryLabel
                angle={45}
                verticalAnchor="middle"
                textAnchor="start"
                style={{ fontSize: 8 }}
              />
            }
          />

          <VictoryAxis
            dependentAxis
            standalone={false}
            domain={{ y: toJS(this.domainY) }}
            tickFormat={(value) => String(value)}
          />

          <VictoryLine
            style={{
              data: { stroke: '#127263' },
            }}
            data={toJS(this.usersCountData)}
            name="total"
            x={this.props.formatDate}
            y="totalCount"
            interpolation="monotoneX"
            animate={{
              duration: 1000,
              onLoad: { duration: 1000 },
            }}
          />

          <VictoryLine
            style={{
              data: { stroke: '#91E1D5' },
            }}
            data={toJS(this.usersCountData)}
            name="active"
            x={this.props.formatDate}
            y="activeCount"
            animate={{
              duration: 1000,
              onLoad: { duration: 1000 },
            }}
          />
        </VictoryChart>

        <div className="legenda">
          <div className="legendaPoint" style={{ background: '#223F38' }}></div>
          <div>
            {t('usersChart.maxAccountsNum')}
            {this.subscriptionsTreshold && `(${this.subscriptionsTreshold})`}
          </div>
          <div className="legendaPoint" style={{ background: '#127263' }}></div>
          <div>{t('usersChart.maxAssignedAccountsNum')}</div>
          <div className="legendaPoint" style={{ background: '#91E1D5' }}></div>
          <div>{t('usersChart.maxActivatedAccountsNum')}</div>
        </div>
      </div>
    )
  }
}

ContractInisghtsUsersChart.propTypes = {
  selectedStudyIds: PropTypes.array.isRequired,
  period: PropTypes.oneOf(['year', 'month', 'week']).isRequired,
  dataBackShift: PropTypes.number.isRequired,
  query: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
}

export default withTranslation()(ContractInisghtsUsersChart)
