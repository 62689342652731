import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import ResetConsents from '../components/ResetConsents'

@observer
class ResetConsentsPage extends Component {
  render() {
    const { admin, go } = this.props
    return <ResetConsents admin={admin} go={go} />
  }
}

ResetConsentsPage.propTypes = {
  admin: PropTypes.bool,
  go: PropTypes.func,
}

export default ResetConsentsPage
