import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { withTranslation } from 'react-i18next'

import Snackbar from '../components/Snackbar'
import EulaComponent from '../components/Eula'
import PrivacyStatementComponent from '../components/PrivacyStatement'
import request from '../utils/request'
import packageJson from '../../package.json'

@observer
class TermsAndConditions extends Component {
  @observable acceptedEula = false
  @observable acceptedPrivacyStatement = false
  @observable loading = false
  @observable error = ''

  @action.bound
  checkEula() {
    this.acceptedEula = !this.acceptedEula
  }

  @action.bound
  checkPrivacyStatement() {
    this.acceptedPrivacyStatement = !this.acceptedPrivacyStatement
  }

  sendConsent(endpoint) {
    const { t } = this.props

    this.loading = true
    request('sherpa-caregiver', endpoint, {
      method: 'post',
      body: {buildVersion: packageJson.version, udiVersion: packageJson.udiVersion}
    })
      .then((response) => {
        if (response.status === 'success') {
          this.props.user.getData().finally(() => this.props.go())
        }
      })
      .catch((error) => {
        this.error = t('main.genericError')
      })
      .finally(() => (this.loading = false))
  }

  render() {
    return (
      <Fragment>
        {this.props.eula ? (
          <EulaComponent
            checkCheckbox={this.checkEula}
            acceptEula={() => this.sendConsent('acceptEula')}
            accepted={this.acceptedEula}
            loading={this.loading}
          />
        ) : (
          <PrivacyStatementComponent
            checkCheckbox={this.checkPrivacyStatement}
            acceptPrivacyStatement={() =>
              this.sendConsent('acceptPrivacyStatement')
            }
            accepted={this.acceptedPrivacyStatement}
            loading={this.loading}
          />
        )}
        {this.error && <Snackbar message={this.error} />}
      </Fragment>
    )
  }
}

TermsAndConditions.propTypes = {
  go: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default withTranslation()(TermsAndConditions)
