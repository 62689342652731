import PropTypes from 'prop-types'
import React from 'react'

const axisPropTypes = {
  ticks: PropTypes.array.isRequired,
  scale: PropTypes.func.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  labelFormat: PropTypes.func,
  tickSize: PropTypes.number,
}

const valueAsString = (d) =>
  typeof d === 'object' && typeof d.toString === 'function' ? d.toString() : d

const defaultProps = {
  tickSize: 5,
  x: 0,
  y: 0,
  labelFormat: valueAsString,
}

const styles = {
  tickLabel: {
    textAnchor: 'middle',
    fontSize: 10,
    fontWeight: 'bold',
  },
}

const getKey = (d) => JSON.stringify(d)

const XAxis = ({ ticks, scale, x, y, labelFormat, tickSize }) => {
  const tickMarks = ticks.map((d, i) => {
    return (
      <g transform={`translate(${scale(d)}, 0)`} key={getKey(d)}>
        <text x={0} y={tickSize} style={styles.tickLabel} dy={'.71em'}>
          {labelFormat(d)}
        </text>
      </g>
    )
  })
  return <g transform={`translate(${x}, ${y})`}>{tickMarks}</g>
}
XAxis.propTypes = { ...axisPropTypes, width: PropTypes.number.isRequired }
XAxis.defaultProps = defaultProps

const YAxis = ({ ticks, height, width }) => {
  const tickMarks = ticks.map((d, i) => {
    return (
      <g
        transform={`translate(0, ${(height / ticks.length) * i})`}
        key={getKey(d)}
      >
        <rect
          x={0}
          y={0}
          style={{ fill: i % 2 === 0 ? '#e1e1e1' : 'white' }}
          width={width}
          height={Math.ceil(height / ticks.length)}
        />
      </g>
    )
  })
  return <g>{tickMarks}</g>
}
YAxis.propTypes = { ...axisPropTypes, height: PropTypes.number.isRequired }
YAxis.defaultProps = defaultProps

export { XAxis, YAxis }
