import { observable, action, reaction } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import ReactGA from 'react-ga4'

import { CircularProgress } from '@material-ui/core'

import InsightModel from '../models/Insight'
import InsightComponent from '../components/Insight'

@observer
class Insight extends Component {
  @observable startDate = null
  @observable endDate = null
  @observable date = null
  @observable dialog = null
  @observable timeRange = 12
  walking = { line: [], points: [], area: [] }
  sdmt = { line: [], points: [], area: [] }
  @observable testsSummary = {}
  notes = []
  questions = []
  profile = {}

  constructor(props) {
    super(props)
    this.model = new InsightModel(this.props.userId, this.props.contractId)

    reaction(
      () => this.model.init,
      (init) => {
        if (!init) {
          this.startDate = this.model.startDate
          this.timeRange = this.setTimeRange(this.startDate)
          this.endDate = moment().endOf('month')
          this.date = `${this.startDate.format(
            'MMM YYYY',
          )} - ${this.endDate.format('MMM YYYY')}`
          this.getFilteredData()
        }
      },
    )
  }

  @action.bound
  async prev() {
    this.startDate = this.startDate.subtract(1, 'months')
    this.endDate = this.endDate.subtract(1, 'months')
    this.date = `${this.startDate.format('MMM YYYY')} - ${this.endDate.format(
      'MMM YYYY',
    )}`

    await this.getFilteredData()
  }

  @action.bound
  async next() {
    this.startDate = this.startDate.add(1, 'months')
    this.endDate = this.endDate.add(1, 'months')
    this.date = `${this.startDate.format('MMM YYYY')} - ${this.endDate.format(
      'MMM YYYY',
    )}`

    await this.getFilteredData()
  }

  setTimeRange(startDate) {
    const monthsOnChart = moment().diff(moment(startDate), 'month')

    if (monthsOnChart >= 12) {
      return 12
    } else if (monthsOnChart <= 3) {
      return 3
    }

    // if monthsSinceLastExperiment > 3 && monthsSinceLastExperiment < 12
    return monthsOnChart
  }

  async getFilteredData() {
    this.walking = this.model.getExperiment(
      this.startDate,
      this.timeRange,
      'walking',
    )
    this.sdmt = this.model.getExperiment(this.startDate, this.timeRange, 'sdmt')
    this.notes = this.model.getNotes(this.startDate, this.timeRange)
    this.questions = this.model.getQuestions(this.startDate, this.timeRange)
    this.testsSummary = await this.model.getTestsSummary(
      this.startDate,
      this.timeRange,
    )
  }

  @action.bound
  openDialog(type) {
    this.dialog = type
  }

  @action.bound
  closeDialog() {
    this.dialog = null
  }

  @action.bound
  updateInquiries(inquiries) {
    const { userModel, contractId} = this.props
    const userId = this.model.userId
    userModel.updateInquiries(userId, inquiries, contractId )
  }

  componentDidMount() {
    const { userModel } = this.props
    ReactGA.send({
      hitType: 'pageview',
      page: `/insight`,
      title: `User Insight page - ${this.props.contractId} - ${userModel._id}`,
    })
  }

  render() {
    const { userModel } = this.props
    if (this.model.init) {
      return <CircularProgress />
    }

    return (
      <InsightComponent
        prev={this.prev}
        next={this.next}
        date={this.date}
        startDate={this.startDate}
        walking={this.walking}
        sdmt={this.sdmt}
        notes={this.notes}
        questions={this.questions}
        testsSummary={this.testsSummary}
        profile={this.model.profile}
        loading={this.model.init}
        dialog={this.dialog}
        openDialog={this.openDialog}
        closeDialog={this.closeDialog}
        updateInquiries={this.updateInquiries}
        inquiries={this.model.inquiries}
        versions={this.model.versions}
        defaultInquiries={userModel.contracts.find((contract) => (contract._id === this.props.contractId))?.inquiries || []}
        timeRange={this.timeRange}
      />
    )
  }
}
export default Insight
