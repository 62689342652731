import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons'
import { withStyles, styled } from '@material-ui/styles'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  InputAdornment,
  TextField,
  IconButton,
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import Navigator from '../Navigator'
import './devices.css'

const StyledSearchFieldsWrapper = styled('div')({
  '& > div[class*=MuiTextField-root]': {
    border: '1px solid #000',
    borderRadius: '16px',
    padding: '5px 13px',
    height: '41px',
  },
  position: 'static',
})

@observer
class DevicesTable extends Component {
  constructor(props) {
    super(props)

    props.userModel.getUsersVersions(props.userModel.selectedVersionContract)
  }

  formatDate(date) {
    return moment(new Date(date)).format('DD-MM-YYYY HH:mm')
  }

  formatDateOnly(date) {
    return moment(new Date(date)).format('DD-MM-YYYY')
  }

  @action.bound
  handleInputChange(event) {
    this.props.userModel.searchVersionUserByParam(event.target.value)
  }

  @action.bound
  orderAndSortBy(sortBy) {
    this.props.userModel.sortAndOrderVersionTableBy(sortBy)
  }

  @action.bound
  navigator(type) {
    this.props.userModel.getUsersVersions(type)
  }

  render() {
    const { userModel, devicesRecords, t } = this.props

    const {
      searchedVersionProp,
      clearSearchVersionsTableByParam,
      orderVersionDirection,
      sortVersionBy,
      pageVersionNumber,
      lastVersionPage,
    } = userModel

    if (!devicesRecords.length)
      return (
        <section className="devices-wrapper">
          <Typography variant="h6" className="device-heading">
            {t('devicesPage.noUsers')}
          </Typography>
        </section>
      )

    return (
      <section className="devices-wrapper">
        <Typography variant="h6" className="device-heading">
          {t('devicesPage.users')}
        </Typography>
        <div className="devices-table-header">
          <div>
            <StyledSearchFieldsWrapper>
              <TextField
                placeholder={t('table.typeNameOrEmail')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchedVersionProp.length > 0 && (
                        <IconButton
                          aria-label="clear searchfield button"
                          onClick={clearSearchVersionsTableByParam}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={this.handleInputChange}
                value={searchedVersionProp}
              />
            </StyledSearchFieldsWrapper>
          </div>
        </div>
        <div className="devices-table">
          <Paper elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      direction={orderVersionDirection}
                      active={sortVersionBy === 'userId'}
                      onClick={() => {
                        this.orderAndSortBy('userId')
                      }}
                    >
                      {t('devicesPage.userId')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderVersionDirection}
                      active={sortVersionBy === 'email'}
                      onClick={() => {
                        this.orderAndSortBy('email')
                      }}
                    >
                      {t('table.email')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderVersionDirection}
                      active={sortVersionBy === 'appVersion'}
                      onClick={() => {
                        this.orderAndSortBy('appVersion')
                      }}
                    >
                      {t('devicesPage.msSherpaVersion')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      direction={orderVersionDirection}
                      active={sortVersionBy === 'appOS'}
                      onClick={() => {
                        this.orderAndSortBy('appOS')
                      }}
                    >
                      {t('devicesPage.operatingSystem')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t('devicesPage.osVersion')}</TableCell>
                  <TableCell>{t('devicesPage.phoneModel')}</TableCell>
                  <TableCell>{t('devicesPage.activationDate')}</TableCell>
                  <TableCell>{t('devicesPage.lastActivity')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devicesRecords.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {record.userId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {record.email}
                    </TableCell>
                    <TableCell>{record.appVersion || '-'}</TableCell>
                    <TableCell>{record.appOS || '-'}</TableCell>
                    <TableCell>{record.OSVersion || '-'}</TableCell>
                    <TableCell>{record.phoneModel || '-'}</TableCell>
                    <TableCell>
                      {record.createdAt
                        ? this.formatDate(record.createdAt)
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {record.lastExperimentDate
                        ? this.formatDateOnly(record.lastExperimentDate)
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        {(lastVersionPage && pageVersionNumber === 1) ||
        !devicesRecords.length ? null : (
          <Navigator
            next={!lastVersionPage ? () => this.navigator('next') : null}
            last={!lastVersionPage ? () => this.navigator('last') : null}
            prev={pageVersionNumber !== 1 ? () => this.navigator('prev') : null}
            first={
              pageVersionNumber !== 1 ? () => this.navigator('first') : null
            }
            date={pageVersionNumber}
          />
        )}
      </section>
    )
  }
}

DevicesTable.propTypes = {
  admin: PropTypes.bool,
  go: PropTypes.func,
}

export default withTranslation()(DevicesTable)
