import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import {
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import PrivacyStatementText from '../PrivacyStatement/PrivacyStatementText'

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: '8px',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

@observer
class PrivacyStatement extends Component {
  render() {
    const {
      checkCheckbox,
      accepted,
      acceptPrivacyStatement,
      loading,
      classes,
      t,
    } = this.props
    return (
      <div>
        <PrivacyStatementText height="calc(100vh - 350px)" />
        <div style={{ paddingLeft: '15%' }}>
          <Typography>{t('privacyStatement.proceedPrompt')}</Typography>
          <div style={styles.wrapper}>
            <FormControlLabel
              control={<Checkbox onChange={checkCheckbox} />}
              label={t('privacyStatement.acceptPrivacyStatement')}
            />
            <div style={styles.buttonWrapper}>
              <Button
                variant="contained"
                disabled={!accepted || loading}
                onClick={acceptPrivacyStatement}
              >
                {t('main.continue')}
              </Button>
              {loading && (
                <CircularProgress size={24} style={styles.buttonProgress} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PrivacyStatement.propTypes = {
  checkCheckbox: PropTypes.func.isRequired,
  acceptPrivacyStatement: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  loading: PropTypes.bool,
}

export default withTranslation()(PrivacyStatement)
