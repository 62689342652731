import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, computed, action, reaction } from 'mobx'
import {
  VictoryChart,
  VictoryTheme,
  VictoryGroup,
  VictoryTooltip,
  VictoryStack,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryBar,
} from 'victory'

import request from '../../utils/request'

import './ContractInisghtsExperimentsChart.scss'

@observer
class ContractInisghtsExperimentsChart extends Component {
  @observable data = []
  @observable domainY = [0, 1001]
  @observable loading = true

  @computed get noData() {
    return (this.data || []).some((data) => !data.totalCount)
  }

  @action.bound
  async getData() {
    const studyIds = {}

    if (this.props.selectedStudyIds.length) {
      studyIds['studyIds[]'] = this.props.selectedStudyIds
    }

    const { data, subscriptionsTreshold } = await request(
      'sherpa-graph',
      this.props.experimentType === 'pro'
        ? 'getScheduledObservations'
        : 'getScheduledExperiments',
      {
        query: {
          ...studyIds,
          ...this.props.query,
          type: this.props.experimentType,
        },
      },
    )

    this.data = data
    this.subscriptionsTreshold = subscriptionsTreshold
  }

  constructor(props) {
    super(props)

    reaction(
      () =>
        [
          this.props.period,
          this.props.dataBackShift,
          ...this.props.selectedStudyIds,
        ].join('|'),
      () => {
        this.loading = true
        this.getData()
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.data,
      () => {
        this.loading = false
      },
    )

    reaction(
      () => this.loading,
      () => this.props.setLoading(this.loading),
      { fireImmediately: true },
    )
  }

  render() {
    return (
      <div className="experimentsChartComponent">
        <VictoryChart
          theme={VictoryTheme.material}
          style={{ data: { paddingTop: '20px' } }}
          domainPadding={{ x: 30, y: 30 }}
        >
          <VictoryLegend
            x={125}
            y={10}
            title={this.props.title}
            centerTitle
            orientation="horizontal"
            gutter={20}
            style={{ title: { fontSize: 17 } }}
            data={[]}
          />

          <VictoryAxis
            standalone={false}
            tickLabelComponent={
              <VictoryLabel
                angle={45}
                verticalAnchor="middle"
                textAnchor="start"
                style={{ fontSize: 8 }}
              />
            }
          />

          <VictoryAxis
            dependentAxis
            standalone={false}
            tickFormat={(value) => String(value)}
          />

          {(this.data || []).map((entry, index) => {
            const xLabel = this.props.formatDate(entry)
            const { scheduledTests, completedTests } = entry

            return (
              <VictoryGroup key={`group-${xLabel}`}>
                <VictoryStack
                  colorScale={['#127263', '#91E1D5']}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 1000 },
                  }}
                  labels={() =>
                    `${Math.round((completedTests * 100) / scheduledTests)}%`
                  }
                  labelComponent={
                    <VictoryLabel
                      angle={270}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      dx={20}
                      dy={0}
                    />
                  }
                >
                  <VictoryBar
                    data={[{ x: xLabel, y: completedTests }]}
                    labels={() => `${completedTests} done`}
                    labelComponent={<VictoryTooltip renderInPortal={true} />}
                    barWidth={() => 10}
                  />
                  <VictoryBar
                    data={[{ x: xLabel, y: scheduledTests - completedTests }]}
                    labels={() =>
                      `${scheduledTests - completedTests} not completed`
                    }
                    labelComponent={<VictoryTooltip renderInPortal={true} />}
                    barWidth={() => 10}
                  />
                </VictoryStack>
              </VictoryGroup>
            )
          })}
        </VictoryChart>
      </div>
    )
  }
}

ContractInisghtsExperimentsChart.propTypes = {
  selectedStudyIds: PropTypes.array.isRequired,
  period: PropTypes.oneOf(['year', 'month', 'week']).isRequired,
  query: PropTypes.object.isRequired,
  dataBackShift: PropTypes.number.isRequired,
  setLoading: PropTypes.func.isRequired,
  experimentType: PropTypes.oneOf(['sdmt', 'walking', 'pro']).isRequired,
  formatDate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default ContractInisghtsExperimentsChart
