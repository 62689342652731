import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, computed, action, toJS } from 'mobx'
import { Select, MenuItem, FormHelperText } from '@material-ui/core'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import Navigator from '../components/Navigator'
import ContractInisghtsUsersChart from '../components/ContractInisghtsUsersChart'
import ContractInisghtsExperimentsChart from '../components/ContractInisghtsExperimentsChart'
import request from '../utils/request'

@observer
class ContractInsights extends Component {
  @observable period = 'month'
  @observable usersChartLoading = true
  @observable dataBackShift = 0
  @observable studies = []
  @observable studiesLoaded = false
  @observable selectedStudyIds = []

  @computed get loading() {
    return this.usersChartLoading
  }

  @computed get query() {
    return this._queryParams ? this._queryParams[this.period]() : {}
  }

  @computed get formatDate() {
    return this._formatters[this.period]
  }

  @computed get dateRange() {
    const startTime = moment(this.query.startTime).startOf(this.period)
    let endTime = moment(this.query.endTime)
      .startOf(this.period)
      .subtract(1, 'hour')

    if (this.period === 'year') {
      endTime = moment(this.query.endTime)
        .endOf(this.period)
        .subtract(1, 'hour')
    }

    const startObj = { year: startTime.year(), month: startTime.month() }
    const endObj = { year: endTime.year(), month: endTime.month() }
    return `${this.formatDate(startObj)} - ${this.formatDate(endObj)}`
  }

  @action.bound
  setUsersChartLoading(loading) {
    this.usersChartLoading = loading
  }

  _queryParams = {
    month: () => {
      const endTime = moment()
        .subtract(this.dataBackShift, 'months')
        .toDate()
        .toISOString()
      const startTime = moment()
        .subtract(12 + this.dataBackShift, 'months')
        .toDate()
        .toISOString()

      return { startTime, endTime, period: this.period }
    },
    year: () => {
      const endTime = moment()
        .subtract(this.dataBackShift, 'years')
        .toDate()
        .toISOString()
      const startTime = moment()
        .subtract(4 + this.dataBackShift, 'years')
        .subtract(1, 'days')
        .toDate()
        .toISOString()

      return { startTime, endTime, period: this.period }
    },
  }

  _formatters = {
    month: (data) => {
      if (this.loading || !('month' in data)) {
        return '...'
      }

      const formatter = new Intl.DateTimeFormat('en', { month: 'short' })
      const monthName = formatter.format(new Date(data.year, data.month, 1))
      const yearShort = data.year.toString().substr(2, 2)

      return `${monthName} ${yearShort}`
    },
    year: (data) => {
      if (this.loading) {
        return '...'
      }

      return String(data.year)
    },
  }

  componentDidMount() {
    request('sherpa-caregiver', 'caregiverInfo', {
      query: this._queryParams[this.period](),
    }).then(({ contracts }) => {
      this.studies = contracts.map((contract) => ({
        id: contract._id,
        name: contract.title,
        license: contract.license,
        subscriptions: contract.totalSubscriptions,
      }))

      this.studiesLoaded = true
    })
  }

  @action.bound
  changePeriod = (period) => {
    this.dataBackShift = 0
    this.period = period
  }

  @action.bound
  goForward = () => {
    if (this.dataBackShift > 0) {
      this.dataBackShift--
    }
  }

  @action.bound
  goBackward = () => {
    this.dataBackShift++
  }

  @action.bound
  async handleChangeMultiple(event) {
    const { value } = event.target

    this.selectedStudyIds = value
  }

  render() {
    const { t } = this.props

    return (
      <div className="contractInsightContainer">
        <div className="navigator">
          <Navigator
            date={this.dateRange}
            next={this.goForward}
            prev={this.goBackward}
            color={'rgb(27, 158, 119)'}
            loading={this.loading}
            nextDisabled={!this.dataBackShift}
            prevDisabled={this.noData}
            fullWidth={false}
          >
            <div className="periodSelect" key="periodSelect">
              <FormHelperText>{t('contracts.period')}</FormHelperText>
              <Select
                labelid="periodLabel"
                value={this.period}
                onChange={({ target: { value } }) => this.changePeriod(value)}
                name="period"
              >
                <MenuItem key="year" value="year">
                  {t('contracts.year')}
                </MenuItem>
                <MenuItem key="month" value="month">
                  {t('contracts.month')}
                </MenuItem>
              </Select>
            </div>

            <div className="studySelect" key="studySelect">
              <FormHelperText>{t('contracts.contracts')}</FormHelperText>
              <Select
                multiple
                displayEmpty
                labelid="studyLabel"
                value={toJS(this.selectedStudyIds)}
                renderValue={(selectedIds) => {
                  return (
                    this.studies
                      .filter((study) => selectedIds.includes(study.id))
                      .map(({ name }) => name)
                      .join(', ') || t('contracts.contracts')
                  )
                }}
                onChange={this.handleChangeMultiple}
                name="studyId"
              >
                {this.studies.map(({ id, name, license, subscriptions }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    className={license ? 'item-bold' : ''}
                  >
                    {name} {license ? ` (${subscriptions})` : null}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Navigator>
        </div>

        <div className="charts-row">
          <ContractInisghtsUsersChart
            period={this.period}
            selectedStudyIds={toJS(this.selectedStudyIds)}
            dataBackShift={this.dataBackShift}
            query={toJS(this.query)}
            formatDate={this.formatDate}
            setLoading={this.setUsersChartLoading}
          />
        </div>

        <div className="legenda-row">
          <div className="legendaPoint" style={{ background: '#127263' }}></div>
          <div className="legendaText" style={{ marginRight: 30 }}>
            {t('contracts.completedTests')}
          </div>
          <div className="legendaPoint" style={{ background: '#91E1D5' }}></div>
          <div className="legendaText">{t('contracts.scheduledTests')}</div>
        </div>

        <div className="charts-row">
          <ContractInisghtsExperimentsChart
            period={this.period}
            selectedStudyIds={toJS(this.selectedStudyIds)}
            dataBackShift={this.dataBackShift}
            query={toJS(this.query)}
            formatDate={this.formatDate}
            setLoading={this.setUsersChartLoading}
            experimentType={'walking'}
            title={'2MWT'}
          />

          <ContractInisghtsExperimentsChart
            period={this.period}
            selectedStudyIds={toJS(this.selectedStudyIds)}
            dataBackShift={this.dataBackShift}
            query={toJS(this.query)}
            formatDate={this.formatDate}
            setLoading={this.setUsersChartLoading}
            experimentType={'sdmt'}
            title={'SDMT'}
          />

          <ContractInisghtsExperimentsChart
            period={this.period}
            selectedStudyIds={toJS(this.selectedStudyIds)}
            dataBackShift={this.dataBackShift}
            query={toJS(this.query)}
            formatDate={this.formatDate}
            setLoading={this.setUsersChartLoading}
            experimentType={'pro'}
            title={t('contracts.questionnaires')}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(ContractInsights)
