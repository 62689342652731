import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import {
  FIXED_FEE_CONTRACT_TYPE,
  RECURRENT_CONTRACT_TYPE,
} from '../../models/Types'
import { withStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

import Snackbar from '../Snackbar'

const styles = (theme) => ({
  selectLabel: {
    marginTop: 4,
    fontSize: 12,
  },
})

@observer
class EditContract extends Component {
  @observable _id = null
  // SHER-68 force type for new contracts to be RECURRENT
  @observable contractType = RECURRENT_CONTRACT_TYPE
  @observable client = null
  @observable title = null
  @observable subscriptions = null
  @observable activationPeriod = null
  @observable renewalPeriod = null
  @observable startDate = moment()
  @observable endDate = moment()
  @observable submittedSubscriptions = 0
  @observable inquiries = ['walking', 'sdmt', 'questionnaire']
  @observable firstname = null
  @observable lastname = null
  @observable email = null
  @observable language = null
  @observable insertion = null
  @computed get contract() {
    return {
      _id: this._id,
      contractType: this.contractType,
      client: this.client,
      title: this.title,
      subscriptions: this.subscriptions,
      startDate: this.startDate,
      endDate: this.endDate,
      activationPeriod: this.activationPeriod,
      renewalPeriod: this.renewalPeriod,
      inquiries: this.inquiries,
      submittedSubscriptions: this.submittedSubscriptions,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      language: this.language,
      insertion: this.insertion,
    }
  }

  defaultInquiries = [
    { _id: 'walking', name: '2MWT' },
    { _id: 'sdmt', name: 'SDMT' },
    { _id: 'questionnaire', name: 'PROs' },
    { _id: 'dmsg', name: 'DMSG Questionnaire' },
  ]

  constructor(props) {
    super(props)
    // recreate current contract if in edit mode
    if (props.currentContract) {
      const {
        _id,
        client,
        title,
        license,
        contractType,
        subscriptions,
        activationPeriod,
        renewalPeriod,
        contractStartDate,
        contractEndDate,
        inquiries,
        submittedSubscriptions,
        firstname,
        lastname,
        email,
        language,
        insertion,
      } = props.currentContract
      this._id = _id
      // SHER-68 If contract type is not set, force type to Recurrent. startDate and and endDate can be undefined
      if (contractStartDate) {
        this.startDate = moment(contractStartDate)
      } else {
        this.startDate = moment()
      }
      if (contractEndDate) {
        this.endDate = moment(contractEndDate)
      } else {
        this.endDate = moment()
      }
      this.contractType = contractType
      this.client = client
      this.title = title
      this.subscriptions = subscriptions
      this.activationPeriod = activationPeriod
      this.renewalPeriod = renewalPeriod
      this.inquiries = inquiries.map((inquiry) => {
        if (inquiry._id === '64a6a0602359e35814ef08fc') {
          return 'dmsg' // use this name for front and backend to save dmsg questionnaire
        }
        return inquiry.name
      })
      this.submittedSubscriptions = submittedSubscriptions
      this.firstname = firstname
      this.lastname = lastname
      this.email = email
      this.language = language
      this.insertion = insertion
    } else {
      // for a new contract
      this.contractType = RECURRENT_CONTRACT_TYPE
      this.client = ''
      this.title = ''
      this.renewalPeriod = 0
      this.activationPeriod = 0
      this.subscriptions = 0
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.language = ''
      this.insertion = ''
    }
  }

  componentDidMount() {
    this.setRecurrentFieldsRendering()
  }

  @action.bound
  changeContractType = (event) => {
    if (event.target.value === FIXED_FEE_CONTRACT_TYPE.toString()) {
      this.contractType = FIXED_FEE_CONTRACT_TYPE
    } else if (event.target.value === RECURRENT_CONTRACT_TYPE.toString()) {
      this.contractType = RECURRENT_CONTRACT_TYPE
    }
    this.setRecurrentFieldsRendering()
  }

  @action.bound
  changeDate = (name) => (date) => {
    this[name] = moment(date)
  }

  @action.bound
  changeValue = (name) => (event) => {
    this[name] = event.target.value
    if (['subscriptions', 'activationPeriod', 'renewalPeriod'].includes(name)) {
      this[name] = parseInt(event.target.value)
    }
  }

  @action.bound
  changeInquiry(inquiryId) {
    if (
      this.inquiries.some(
        (item) => item.toLowerCase() == inquiryId.toLowerCase(),
      )
    ) {
      this.inquiries = this.inquiries.filter(
        (inquiry) => inquiry.toLowerCase() != inquiryId.toLowerCase(),
      )
    } else {
      this.inquiries.push(inquiryId)
    }
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  setRecurrentFieldsRendering() {
    const visible = this.contractType === RECURRENT_CONTRACT_TYPE
    document.getElementById('recurrentExtraFields').style.display = visible
      ? 'block'
      : 'none'
  }

  render() {
    const { loading, error, editContract, addContract, t, classes } = this.props
    let errorMessage = null
    if (error) {
      errorMessage = <Snackbar message={error} />
    }

    if (loading) return <CircularProgress />

    return (
      <div>
        <h1>{t('editContract.title')}</h1>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {!editContract ? (
              <div>
                <Select
                  value={this.contractType.toString()}
                  onChange={this.changeContractType}
                  name="studyId"
                  fullWidth
                >
                  <MenuItem
                    key={'RECURRENT'}
                    value={RECURRENT_CONTRACT_TYPE.toString()}
                  >
                    {t('editContract.recurrent')}
                  </MenuItem>
                  <MenuItem
                    key={'FIXED_FEE'}
                    value={FIXED_FEE_CONTRACT_TYPE.toString()}
                  >
                    {t('editContract.fixedfee')}
                  </MenuItem>
                </Select>
                <TextField
                  id="client"
                  label={t('editContract.institution')}
                  fullWidth
                  value={this.client}
                  onChange={this.changeValue('client')}
                />
                <TextField
                  id="title"
                  label={t('editContract.contractName')}
                  fullWidth
                  value={this.title}
                  onChange={this.changeValue('title')}
                />
              </div>
            ) : null}
            <TextField
              id="subscriptions"
              label={t('editContract.numOfSubscriptions')}
              type="number"
              value={this.subscriptions}
              onChange={this.changeValue('subscriptions')}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} id="commercial-fields">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label={t('editContract.contractStartDate')}
                value={this.startDate}
                onChange={this.changeDate('startDate')}
                format="DD/MM/YYYY"
                animateYearScrolling
                fullWidth
              />
              <DatePicker
                label={t('editContract.contractEndDate')}
                value={this.endDate}
                minDate={this.startDate}
                minDateMessage={t('editContract.gtStartDate')}
                onChange={this.changeDate('endDate')}
                format="DD/MM/YYYY"
                animateYearScrolling
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <div id="recurrentExtraFields">
              <TextField
                id="activationPeriod"
                label={t('editContract.activationPeriod')}
                type="number"
                fullWidth
                value={this.activationPeriod}
                onChange={this.changeValue('activationPeriod')}
              />
              <TextField
                id="renewalPeriod"
                label={t('editContract.renewalPeriod')}
                type="number"
                fullWidth
                value={this.renewalPeriod}
                onChange={this.changeValue('renewalPeriod')}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <h3>Contract admin</h3>
            <TextField
              id="email"
              label={t('editContract.admin.email')}
              fullWidth
              value={this.email}
              onChange={this.changeValue('email')}
            />
            <div>
              <Grid container spacing={1}>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    id="firstname"
                    label={t('editContract.admin.firstname')}
                    value={this.firstname}
                    onChange={this.changeValue('firstname')}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    id="insertion"
                    label={t('editContract.admin.insertion')}
                    value={this.insertion}
                    onChange={this.changeValue('insertion')}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    id="lastname"
                    label={t('editContract.admin.lastname')}
                    value={this.lastname}
                    onChange={this.changeValue('lastname')}
                  />
                </Grid>
                <Grid item lg={2}>
                  <InputLabel
                    id="language-label"
                    className={classes.selectLabel}
                  >
                    {t('editContract.admin.languageLabel')}
                  </InputLabel>
                  <Select
                    fullWidth
                    value={this.language}
                    onChange={this.handleChange('language')}
                    id="language"
                    labelId="language-label"
                    name="language"
                    className={classes.extraMargin}
                  >
                    <MenuItem key="nl" value="nl">
                      {t('editContract.admin.language.nl')}
                    </MenuItem>
                    <MenuItem key="en" value="en">
                      {t('editContract.admin.language.en')}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {editContract && this.type === 'clinical' ? null : (
          <div>
            <h1>{t('editContract.biomarkersSettings')}</h1>
            {_.map(this.defaultInquiries, (inquiry, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={inquiry._id}
                      checked={this.inquiries.some(
                        (item) =>
                          item.toLowerCase() == inquiry._id.toLowerCase(),
                      )}
                      onChange={this.changeInquiry.bind(null, inquiry._id)}
                    />
                  }
                  label={inquiry.name}
                />
              )
            })}
          </div>
        )}
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            addContract(this.contract)
          }}
        >
          {editContract ? t('main.save') : t('editContract.addContract')}
        </Button>

        {errorMessage}
      </div>
    )
  }
}

const EditContractHOC = withStyles(styles)(EditContract)
export default withTranslation()(EditContractHOC)
