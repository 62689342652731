import PropTypes from 'prop-types'
import React from 'react'

const axisPropTypes = {
  ticks: PropTypes.array.isRequired,
  scale: PropTypes.func.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  labelFormat: PropTypes.func,
  tickSize: PropTypes.number,
}

const valueAsString = (d) =>
  typeof d === 'object' && typeof d.toString === 'function' ? d.toString() : d

const defaultProps = {
  tickSize: 5,
  x: 0,
  y: 0,
  labelFormat: valueAsString,
}

const styles = {
  tickLine: {
    fill: 'none',
    stroke: '#dcdcdc',
    shapeRendering: 'crispEdges',
  },
  tickLabel: {
    textAnchor: 'middle',
    fontSize: 10,
    fontWeight: 'bold',
  },
  tickLabelY: {
    textAnchor: 'end',
    fontSize: 10,
    fontWeight: 'bold',
  },
  axisLine: {
    fill: 'none',
    stroke: '#dcdcdc',
    shapeRendering: 'crispEdges',
  },
}

const getKey = (d) => JSON.stringify(d)

const XAxis = ({
  ticks,
  scale,
  width,
  x,
  y,
  labelFormat,
  tickSize,
  borderLine = false,
}) => {
  const tickMarks = ticks.map((d) => {
    const scaled = scale(d)
    if (scaled < 0 || scaled > width) {
      return null
    }
    return (
      <g transform={`translate(${scale(d)}, 0)`} key={getKey(d)}>
        <text x={0} y={tickSize} style={styles.tickLabel} dy={'.71em'}>
          {labelFormat(d)}
        </text>
      </g>
    )
  })
  return (
    <g transform={`translate(${x}, ${y})`}>
      {borderLine ? (
        <line x1={0} x2={width} y1={0} y2={0} style={styles.axisLine} />
      ) : null}
      {tickMarks}
    </g>
  )
}
XAxis.propTypes = { ...axisPropTypes, width: PropTypes.number.isRequired }
XAxis.defaultProps = defaultProps

const YAxis = ({
  ticks,
  scale,
  height,
  width,
  x,
  y,
  labelFormat,
  tickSize,
  borderLine = false,
  text,
}) => {
  const tickMarks = ticks.map((d, i) => {
    const scaled = scale(d)
    if (scaled < 0) return null

    // label for the axis
    // if text is set and it the last text of the y as then display text
    const label = text && i + 1 === ticks.length ? text : labelFormat(d)

    return (
      <g transform={`translate(0, ${scale(d)})`} key={getKey(d)}>
        <line
          x1={0}
          y1={0}
          x2={width}
          y2={0}
          style={{ strokeOpacity: 0.1, stroke: 'grey' }}
        />
        <text
          x={-tickSize}
          y={0}
          style={styles.tickLabelY}
          dy={'.35em'}
          dx={'-.35em'}
        >
          {label}
        </text>
      </g>
    )
  })
  return (
    <g transform={`translate(${x}, 0)`}>
      {borderLine ? (
        <line x1={0} x2={0} y1={0} y2={height} style={styles.axisLine} />
      ) : null}
      {tickMarks}
    </g>
  )
}
YAxis.propTypes = { ...axisPropTypes, height: PropTypes.number.isRequired }
YAxis.defaultProps = defaultProps

export { XAxis, YAxis }
