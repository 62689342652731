import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'

class PrivacyStatementText extends Component {
  render() {
    const { height = 'calc(100vh - 250px)', t } = this.props
    const styles = {
      outer: {
        paddingTop: '3%',
        paddingLeft: '15%',
        paddingRight: '15%',
      },
      wrapper: {
        border: 'solid 2px rgb(18, 114, 99)',
        padding: '15px',
        overflowX: 'scroll',
        height: height,
      },
      heading: {
        fontSize: '24px',
      },
      alignRight: {
        textAlign: 'right',
      },
    }

    return (
      <div style={styles.outer}>
        <div style={styles.wrapper}>
          <p style={styles.heading}>{t('privacyStatement.heading')}</p>
          <p style={styles.alignRight}>{t('privacyStatement.secondHeading')}</p>
          <Trans
            i18nKey="privacyStatement.privacyStatementText"
            components={{
              1: <p />,
              2: <strong />,
              3: <li />,
              4: <a />,
              5: <ul />,
            }}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(PrivacyStatementText)
