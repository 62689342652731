import { observable } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import i18next from 'i18next'
import { initReactI18next, withTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

import AppModel from '../models/App'
import AppComponent from '../components/App'
import nl from '../../locales/nl.json'
import en from '../../locales/en.json'

i18next.use(initReactI18next).init({
  fallbackLng: 'nl',
  debug: true,
  lng: localStorage.getItem('language') || 'nl',
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
})

@observer
class App extends Component {
  @observable appModel = null

  constructor(props) {
    super(props)
    this.appModel = new AppModel(this.props.t)
  }

  FallbackComponent() {
    return <div>An error has occured</div>
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={this.FallbackComponent} showDialog>
        <AppComponent
          initializing={this.appModel.initializing}
          access={this.appModel.access}
          user={this.appModel.user}
        />
      </Sentry.ErrorBoundary>
    )
  }
}
export default withTranslation()(App)
