import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action, computed } from 'mobx'
import moment from 'moment'
import _ from 'lodash'
import DescriptionIcon from '@material-ui/icons/Description'
import { withStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import request from '../../utils/request'
import {
  FIXED_FEE_CONTRACT_TYPE,
  RECURRENT_CONTRACT_TYPE,
} from '../../models/Types'

const styles = () => ({
  reportDownloadButton: {
    cursor: 'pointer',
  },
  dialogShown: {
    display: 'block',
  },
  dialogHidden: {
    display: 'none',
  },
})

@observer
class Contracts extends Component {
  @observable reportDialogOpen = false
  @observable reportDownloading = false
  @observable reportContract = null
  @observable reportMonth = null
  @observable reportMonths
  @observable contracts = []
  @observable filters = ['commercial', 'clinical']
  @computed get totalSubscriptions() {
    return _.sumBy(this.contracts, (contract) => {
      return contract.subscriptions || contract.totalSubscriptions
    })
  }

  @computed get totalSubmittedSubscriptions() {
    return _.sumBy(this.contracts, (contract) => {
      return contract.submittedSubscriptions
    })
  }

  @computed get totalActiveSubscriptions() {
    return _.sumBy(this.contracts, (contract) => {
      return contract.activeSubscriptions
    })
  }

  constructor(props) {
    super(props)
    const now = moment()

    this.contracts = props.contracts
    this.reportMonths = Array(6)
      .fill(0)
      .map((_, i) => {
        const month = now.clone().subtract(i + 1, 'month')

        return {
          start: month.clone().startOf('month').startOf('day'),
          end: month.clone().endOf('month').endOf('day'),
          label: `${month.format('MMMM')} ${month.format('YYYY')}`,
        }
      })
    this.reportMonth = this.reportMonths[0]
  }

  @action.bound
  setReportMonth = ({ target: { value } }) => {
    this.reportMonth = value
  }

  @action.bound
  openReportDialog = (contract) => {
    this.reportContract = contract
    this.reportDialogOpen = true
  }

  @action.bound
  handleReportDialogClose = () => {
    this.reportDialogOpen = false
  }

  @action.bound
  downloadReport = async () => {
    try {
      this.reportDownloading = true

      await request('sherpa-export', 'contractReport', {
        body: {
          start: this.reportMonth.start.format('YYYY-MM-DD'),
          end: this.reportMonth.end.format('YYYY-MM-DD'),
          format: 'pdf',
          contractId: this.reportContract._id,
        },
        method: 'POST',
        authHeader: true,
        blobName: `Report from ${this.reportContract.title} for ${this.reportMonth.label}.pdf`,
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.reportDownloading = false
    }

    this.reportDialogOpen = false
  }

  render() {
    const { go, classes, admin, t } = this.props
    return (
      <div>
        {admin && (
          <Button
            style={{ marginBottom: '10px', marginRight: '10px' }}
            variant="contained"
            onClick={() => {
              go('/addContract')
            }}
          >
            {t('contracts.editContract')}
          </Button>
        )}
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('contracts.title')}</TableCell>
                <TableCell>{t('contracts.organization')}</TableCell>
                <TableCell>{t('contracts.contractType')}</TableCell>
                <TableCell>{t('contracts.startDate')}</TableCell>
                <TableCell>{t('contracts.endDate')}</TableCell>
                <TableCell>{t('contracts.contractSubs')}</TableCell>
                <TableCell>{t('contracts.contractSubmitedSubs')}</TableCell>
                <TableCell>{t('contracts.contractActiveSubs')}</TableCell>
                {admin && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.contracts.map((contract, index) => (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => go(`/contract?contractId=${contract._id}`)}
                  >
                    <div style={{ color: 'blue', textDecoration: 'underline' }}>
                      {contract.title}
                    </div>
                  </TableCell>
                  <TableCell>{contract.client}</TableCell>
                  <TableCell>
                    {contract.contractType === FIXED_FEE_CONTRACT_TYPE
                      ? t('contracts.fixedfee')
                      : t('contracts.recurrent')}
                  </TableCell>
                  <TableCell>
                    {contract.contractStartDate
                      ? moment(contract.contractStartDate).format('DD-MM-YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {contract.contractEndDate
                      ? moment(contract.contractEndDate).format('DD-MM-YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell>{contract.subscriptions || '-'}</TableCell>
                  <TableCell>{contract.submittedSubscriptions}</TableCell>
                  <TableCell>{contract.activeSubscriptions}</TableCell>
                  {admin &&
                  contract.contractType === RECURRENT_CONTRACT_TYPE ? (
                    <TableCell>
                      <DescriptionIcon
                        className={classes.reportDownloadButton}
                        onClick={() => this.openReportDialog(contract)}
                      />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>{t('contracts.total')}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{this.totalSubscriptions}</TableCell>
                <TableCell>{this.totalSubmittedSubscriptions}</TableCell>
                <TableCell>{this.totalActiveSubscriptions}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>

        <Dialog
          open={!!this.reportDialogOpen}
          onClose={this.handleReportDialogClose}
          className={
            this.reportDialogOpen ? classes.dialogShown : classes.dialogHidden
          }
        >
          <DialogTitle>
            {t('contracts.generateReport', {
              title: (this.reportContract && this.reportContract.title) || '',
            })}
          </DialogTitle>
          <DialogContent>
            <InputLabel id="contract-report-label">
              {t('contracts.forMonth')}
            </InputLabel>
            <Select
              labelid="contract-report-label"
              value={this.reportMonth}
              onChange={this.setReportMonth}
            >
              {this.reportMonths.map((month, index) => (
                <MenuItem key={`report-month-${index}`} value={month}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleReportDialogClose} color="primary">
              {t('main.close')}
            </Button>
            <Button
              onClick={this.downloadReport}
              color="primary"
              disabled={this.reportDownloading}
              autoFocus
            >
              {t('main.download')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

Contracts.propTypes = {
  classes: PropTypes.object,
  admin: PropTypes.bool,
  contracts: PropTypes.object,
}

const ContractsHOC = withStyles(styles)(Contracts)
export default withTranslation()(ContractsHOC)
