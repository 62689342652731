import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/styles'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip)

function TriggerTooltip({ text }) {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ marginTop: 5 }}>
        <LightTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
          arrow={true}
        >
          <InfoIcon style={{ cursor: 'pointer' }} onClick={handleTooltipOpen} />
        </LightTooltip>
      </div>
    </ClickAwayListener>
  )
}

TriggerTooltip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TriggerTooltip
