import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { withTranslation } from 'react-i18next'
import AppBar from '../AppBar'

// models
import RouterModel from '../../models/Router'
import MenuModel from '../../models/Menu.js'

// components
import EulaText from '../Eula/Text'
import PrivacyStatementText from '../PrivacyStatement/PrivacyStatementText'
import Loading from '../Loading'
import NoAccess from '../NoAccess'
import Menu from '../Menu'

// pages
import Users from '../../pages/Users'
import EditContract from '../../pages/EditContract'
import Contracts from '../../pages/Contracts'
import Contract from '../../pages/Contract'
import TermsAndConditions from '../../pages/TermsAndConditions'
import ResetConsents from '../../pages/ResetConsents'
import Insight from '../../pages/Insight'
import ContractInsights from '../../pages/ContractInsights'
import SupportPage from '../../pages/Support'
import SettingsPage from '../../pages/Settings'
import CaregiverGroupPage from '../../pages/CaregiverGroup'
import AboutPage from '../../pages/About'
import DevicesPage from '../../pages/Devices'

const styles = {
  content: {
    height: '100%',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '30px',
  },
}

@observer
class Router extends Component {
  @observable router = null

  constructor(props) {
    super(props)

    this.router = new RouterModel({ user: props.user })
  }

  render() {
    const { page, go, params } = this.router
    const { user, initializing, access, t } = this.props
    let content = null
    let title = null
    let leftAction = null
    let leftType = 'back'
    const rightType = 'none'
    const rightAction = null

    if (initializing) {
      content = <Loading />
    } else if (!initializing && !access) {
      content = <NoAccess />
      leftAction = user.logout
    } else {
      switch (page) {
        case '/acceptEula':
          title = 'EULA'
          leftType = 'none'
          content = (
            <TermsAndConditions
              user={user}
              go={() =>
                go(
                  `${
                    !user.privacyStatement
                      ? '/acceptPrivacyStatement'
                      : user.caregiver && !user.contractAdmin
                      ? '/'
                      : '/contracts'
                  }`,
                )
              }
              eula
            />
          )
          break
        case '/eula':
          title = 'EULA'
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <EulaText />
          break
        case '/acceptPrivacyStatement':
          title = t('routes.privacy')
          leftType = 'none'
          content = (
            <TermsAndConditions
              user={user}
              go={() =>
                go(
                  `${
                    user.caregiver && !user.contractAdmin ? '/' : '/contracts'
                  }`,
                )
              }
            />
          )
          break
        case '/privacyStatement':
          title = t('routes.privacy')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <PrivacyStatementText />
          break
        case '/contracts':
          title = t('routes.contracts')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = (
            <Contracts contracts={user.contracts} admin={user.admin} go={go} />
          )
          break
        case '/resetConsents':
          title = t('routes.resetConsent')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <ResetConsents admin={user.admin} go={go} />
          break
        case '/devices':
          title = t('routes.devices')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <DevicesPage userModel={user} admin={user.admin} go={go} />
          break
        case '/contract':
          title = t('routes.contract')
          leftAction = () => go('/contracts')
          content = (
            <Contract
              {...params}
              contracts={user.contracts}
              go={go}
              getData={user.getData}
              admin={user.admin}
            />
          )
          break
        case '/addContract':
          title = t('routes.addContract')
          leftAction = () => go('/contracts')
          content = <EditContract go={go} getData={user.getData} />
          break
        case '/editContract':
          title = t('routes.editContract')
          leftAction = () => go(`/contract?contractId=${params.contractId}`)
          content = (
            <EditContract
              {...params}
              go={go}
              contract={user.getContractById(params.contractId)}
              getData={user.getData}
            />
          )
          break
        case '/caregiverGroup':
          title = t('routes.caregiverGroup')
          leftAction = () => go(`/contract?contractId=${params.contractId}`)
          content = (
            <CaregiverGroupPage {...params} go={go} getData={user.getData} />
          )
          break

        case '/insight':
          title = t('routes.insight')
          leftAction = () => go('/')
          content = <Insight {...params} userModel={user} />
          break
        case '/contractInsights':
          title = t('routes.contractStatistics')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <ContractInsights {...params} />
          break
        case '/support':
          title = t('routes.support')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <SupportPage {...params} />
          break
        case '/settings':
          title = t('routes.settings')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <SettingsPage {...params} revalidateData={user.getData} />
          break
        case '/about':
          title = t('routes.about')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <AboutPage {...params} />
          break
        default:
          title = t('routes.patientOverview')
          leftType = 'menu'
          leftAction = MenuModel.toggle
          content = <Users go={go} userModel={user} />
          break
      }
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Menu
          go={go}
          logout={user.logout}
          admin={user.admin}
          user={user}
          caregiver={user.caregiver}
          contractAdmin={user.contractAdmin}
        />
        <AppBar
          fixed={true}
          color="white"
          theme={{ background: '#9eebcf' }}
          title={title}
          leftAction={leftAction}
          leftType={leftType}
          rightAction={rightAction}
          rightType={rightType}
        />
        <div style={styles.content}>{content}</div>
      </div>
    )
  }
}

export default withTranslation()(Router)
