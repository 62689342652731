import { observable, action, reaction, computed } from 'mobx'
import _, { debounce } from 'lodash'
import auth0 from '../services/auth0'
import request from '../utils/request'
import {
  RECURRENT_CONTRACT_TYPE,
  FIXED_FEE_CONTRACT_TYPE,
} from '../models/Types'

export default class User {
  @observable initAdmin = true
  @observable initCaregiver = true
  @computed get init() {
    if (this.caregiver && (this.admin || this.contractAdmin))
      return this.initAdmin || this.initCaregiver
    if (this.caregiver) return this.initCaregiver
    if (this.admin || this.contractAdmin) return this.initAdmin
  }

  @observable _id = null
  @observable email = null
  @observable eula = null
  @observable privacyStatement = null
  @observable roles = []
  @observable permissions = {}
  @observable contracts = []
  @observable caretakers = []
  @observable caretakerAll = []
  @observable searchedProp = ''
  @observable orderDirection = 'desc'
  @observable sortBy = 'createdAt'
  @observable searchedBirthday = null
  @observable validDate = true
  @observable selectedContract = ''
  @observable caregiverContracts = []

  // devices version page
  @observable selectedVersionContract = ''
  @observable appVersions = {}
  @observable systemVersions = {}
  @observable appVersionsTotal = 0
  @observable versionsUsersCount = 0
  @observable searchedVersionProp = ''
  @observable orderVersionDirection = 'desc'
  @observable sortVersionBy = 'createdAt'
  @observable pageVersionNumber = 1
  @observable lastVersionPage = false
  @observable usersVersionsList = []

  @computed get admin() {
    return this.roles.includes('Admin')
  }

  @computed get caregiver() {
    return this.roles.includes('Caregiver')
  }

  @computed get contractAdmin() {
    return this.roles.includes('ContractAdmin')
  }

  @computed get canViewUserData() {
    return this.permissions.canViewUserData || false
  }

  @observable caretakersCount = 0
  @observable pageNumber = 1
  @observable lastPage = false
  steps = 50

  constructor(t) {
    this.t = t

    reaction(
      () => auth0._id,
      (_id) => {
        if (_id) {
          const roles = auth0.getJwtParam('https://orikami-api.nl/roles')
          const permissions = auth0.getJwtParam(
            'https://orikami-api.nl/permissions',
          )
          const tokenValid = auth0.loggedIn()

          if (tokenValid) {
            this._id = _id
            this.email = auth0.email
            this.roles = roles || []
            this.permissions = permissions || {}
            this.getData()
          } else {
            auth0.login()
          }
        } else if (!window.location.hash.includes('access_token')) {
          auth0.login()
        }
      },
      { fireImmediately: true },
    )
  }

  @action.bound
  getData() {
    const promises = []
    this.initCaregiver = true
    this.initAdmin = true
    if (this.caregiver) {
      const promise = request('sherpa-caregiver', 'caregiverInfo', {
        method: 'GET',
        query: {
          language: localStorage.getItem('language') || 'nl',
        },
      })
        .then((data) => {
          if (!this.admin && !this.contractAdmin) {
            this.contracts = data.contracts
          }
          this.caregiverContracts = data.contracts
          this.selectedContract = data.contracts[0]?._id || ''
          this.selectedVersionContract = data.contracts[0]?._id || ''
          this.eula = data.eula
          this.privacyStatement = data.privacyStatement
          this.getCaretakers()
        })
        .finally(() => {
          promises.push(promise)
          this.initCaregiver = false
        })
    }

    if (this.admin || this.contractAdmin) {
      const promise = request('sherpa-admin', 'adminInfo', {
        method: 'GET',
        query: {
          language: localStorage.getItem('language') || 'nl',
        },
      })
        .then((data) => {
          this.contracts = data.contracts
          this.selectedVersionContract = data.contracts[0]?._id || ''
          this.contracts.forEach((contract, i) => {
            // SHER-68 If no contract type is present in the json, add contractType with default value FIXED_FEE

            if (
              contract.contractType &&
              contract.contractType === 'FIXED_FEE'
            ) {
              contract.contractType = FIXED_FEE_CONTRACT_TYPE
            } else {
              contract.contractType = RECURRENT_CONTRACT_TYPE
            }
          })
          this.eula = data.eula
          this.privacyStatement = data.privacyStatement
        })
        .finally(() => {
          promises.push(promise)
          this.initAdmin = false
        })
    }
    return Promise.all(promises)
  }

  @action.bound
  async getCaretakers(type) {
    if (type === 'next') {
      this.pageNumber = this.pageNumber + 1
      this.lastPage =
        Math.ceil(this.caretakersCount / this.steps) === this.pageNumber
    } else if (type === 'prev') {
      this.pageNumber = this.pageNumber - 1
      this.lastPage = false
    } else if (type === 'last') {
      this.pageNumber = Math.ceil(this.caretakersCount / this.steps)
      this.lastPage = true
    } else if (type === 'first') {
      this.pageNumber = 1
      this.lastPage = false
    }

    this.initCaregiver = true

    await request('sherpa-caregiver', 'usersList', {
      method: 'GET',
      query: {
        limit: this.steps,
        page: this.pageNumber,
        order: this.orderDirection,
        sortBy: this.sortBy,
        search: this.searchedProp,
        searchBirthday: this.searchedBirthday || '',
        contractId: this.selectedContract,
      },
    }).then((data) => {
      this.caretakers = data?.items || []
      this.caretakersCount = data?.count || 0
      this.initCaregiver = false
      this.lastPage = Math.ceil(data?.count / this.steps) === this.pageNumber
    })
  }

  @action.bound
  async getVersionsStats() {
    await request('sherpa-admin', 'systemVersions', {
      method: 'GET',
      query: {
        contractId: this.selectedVersionContract,
      },
    }).then((data) => {
      this.appVersions = data.appVersions
      this.appVersionsTotal = data.appVersionsTotal
      this.systemVersions = data.systemVersions
    })
  }

  @action.bound
  async getUsersVersions(type) {
    if (type === 'next') {
      this.pageVersionNumber = this.pageVersionNumber + 1
      this.lastVersionPage =
        Math.ceil(this.versionsUsersCount / this.steps) ===
        this.pageVersionNumber
    } else if (type === 'prev') {
      this.pageVersionNumber = this.pageVersionNumber - 1
      this.lastVersionPage = false
    } else if (type === 'last') {
      this.pageVersionNumber = Math.ceil(this.versionsUsersCount / this.steps)
      this.lastVersionPage = true
    } else if (type === 'first') {
      this.pageVersionNumber = 1
      this.lastVersionPage = false
    }

    await request('sherpa-admin', 'usersVersions', {
      method: 'GET',
      query: {
        limit: this.steps,
        page: this.pageVersionNumber,
        order: this.orderVersionDirection,
        sortBy: this.sortVersionBy,
        search: this.searchedVersionProp,
        contractId: this.selectedVersionContract,
      },
    }).then((data) => {
      this.usersVersionsList = data?.items || []
      this.versionsUsersCount = data?.count || 0
      this.lastVersionPage =
        Math.ceil(data?.count / this.steps) === this.pageVersionNumber
    })
  }

  debouncedVersionLog = debounce((text) => {
    this.getUsersVersions()
    if (text.length) {
      this.pageVersionNumber = 1
      if (this.versionsUsersCount <= this.steps) {
        this.lastVersionPage = true
      }
    }
  }, 1000)

  @action.bound
  searchVersionUserByParam(searchedValue) {
    this.searchedVersionProp = searchedValue
    this.debouncedVersionLog(searchedValue)
  }

  @action.bound
  sortAndOrderVersionTableBy(sortBy) {
    this.orderVersionDirection === 'desc'
      ? (this.orderVersionDirection = 'asc')
      : (this.orderVersionDirection = 'desc')
    this.sortVersionBy = sortBy
    this.getUsersVersions()
  }

  @action.bound
  clearSearchVersionsTableByParam() {
    this.searchedVersionProp = ''
    this.debouncedVersionLog('')
  }

  debouncedLog = debounce((text) => {
    this.getCaretakers()
    if (text.length) {
      this.pageNumber = 1
      if (this.caretakersCount <= this.steps) {
        this.lastPage = true
      }
    }
  }, 1000)

  @action.bound
  searchPatientByParam(searchedProp) {
    this.searchedProp = searchedProp
    this.debouncedLog(searchedProp)
  }

  @action.bound
  clearSearchPatientByParam() {
    this.searchedProp = ''
    this.debouncedLog('')
  }

  @action.bound
  setSearchedBirthdayParam(date) {
    if (date === null) {
      this.validDate = true
      this.searchedBirthday = null
      this.debouncedLog('')
      return
    }
    if (date.isValid()) {
      this.validDate = true
      const birthday = date.format('YYYY-MM-DD')
      this.searchedBirthday = birthday
      this.debouncedLog(birthday)
    } else {
      this.validDate = false
    }
  }

  @action.bound
  sortAndOrderBy(sortBy) {
    this.orderDirection === 'desc'
      ? (this.orderDirection = 'asc')
      : (this.orderDirection = 'desc')
    this.sortBy = sortBy
    this.getCaretakers()
  }

  getContractById(contractId) {
    return this.contracts.find((contract) => contract._id === contractId)
  }

  @action.bound
  addUser() {
    this.getCaretakers()
  }

  @action.bound
  deleteUser() {
    this.getCaretakers()
  }

  @action.bound
  selectNewContract(contractId) {
    if (typeof contractId === 'string') {
      this.selectedContract = contractId
      this.getCaretakers()
    }
  }

  @action.bound
  selectNewContractForVersions(contractId) {
    if (typeof contractId === 'string') {
      this.selectedVersionContract = contractId
      this.getVersionsStats()
      this.getUsersVersions()
    }
  }

  @action.bound
  async setActivityUser(userId, contractId, caregiverGroupId, active) {
    let activeState = null
    let activeUntil = null
    let contractEndWarning = false
    await request('sherpa-caregiver', 'userActivity', {
      method: 'POST',
      body: {
        userId,
        contractId,
        caregiverGroupId,
        active,
      },
    }).then((data) => {
      if (data.status === 'success') {
        const userIndex = this.caretakers.findIndex(
          (user) => user._id === userId,
        )
        this.caretakers[userIndex].active = data.active

        activeState = data.active
        activeUntil = data.activeUntil
        contractEndWarning = data.contractEndWarning

        const updatedCaretakers = this.caretakers.map((user) => {
          if (user._id === userId) {
            user.activeUntil = data.activeUntil
          }
          return user
        })
        this.caretakers = updatedCaretakers
      }
    })

    return { activeState, contractEndWarning, activeUntil }
  }

  @action.bound
  async updateInquiries(userId, inquiries, contractId) {
    await request('sherpa-caregiver', 'userInquiries', {
      method: 'PATCH',
      body: {
        userId,
        inquiries,
        contractId
      }
    }).then((data) => {
      if (data.status === 'success') {
        // no extra check here for now
      }
    })
  }

  @action.bound
  logout() {
    this.initAdmin = true
    this.initCaregiver = true
    this._id = null
    this.email = null
    this.roles = []

    auth0.logout()
  }
}
