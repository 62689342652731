import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import SyncRoundedIcon from '@material-ui/icons/SyncRounded'
import EditIcon from '@material-ui/icons/Edit'
import { withTranslation } from 'react-i18next'

import AddContractUser from './AddContractUser'
import SwitchUserRole from './SwitchUserRole'
import AlertDialog from '../Dialog'
import Snackbar from '../Snackbar'
import ChangeGroupName from './ChangeGroupName'

const styles = {
  editIconClass: {
    marginLeft: 15,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
}

@observer
class CaregiverGroup extends Component {
  @observable caregiverGroup = {}
  @observable popupCaregiver = false
  @observable popupSwitchRole = false
  @observable popupChangeName = false
  @observable open = false
  @observable actions = null
  @observable selectedUserId = null
  @observable selectedUserEmail = null

  constructor(props) {
    super(props)
    this.caregiverGroup = props.caregiverGroup
  }

  @action.bound
  toggleChangeNamePopup() {
    this.popupChangeName = !this.popupChangeName
  }

  @action.bound
  togglePopup() {
    this.popupCaregiver = !this.popupCaregiver
  }

  @action.bound
  toggleSwitchRolePopup(selectedUserId, selectedUserEmail) {
    this.popupSwitchRole = !this.popupSwitchRole
    this.selectedUserId = selectedUserId
    this.selectedUserEmail = selectedUserEmail
  }

  @action.bound
  handleClose() {
    this.open = false
    this.actions = null
  }

  @action.bound
  confirmDeleteGroupUser(groupId, userId) {
    const { t, deleteCaregiverGroupUser } = this.props
    this.open = true
    this.actions = [
      <Button key={'cancelButton'} onClick={this.handleClose} color="primary">
        {t('main.cancel')}
      </Button>,
      <Button
        key={'addButton'}
        onClick={() => deleteCaregiverGroupUser(groupId, userId)}
      >
        {t('main.yes')}
      </Button>,
    ]
  }

  render() {
    const {
      message,
      getData,
      confirmationText,
      addContractUser,
      switchContractUserRole,
      changeGroupName,
      t,
    } = this.props
    let messageComponent = null

    if (message) {
      messageComponent = <Snackbar message={message} />
    }
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item>
            <div style={styles.titleRow}>
              <p>
                {t('contracts.title')}: <b>{this.caregiverGroup.name}</b>
              </p>
              <IconButton
                size="small"
                style={styles.editIconClass}
                onClick={() => this.toggleChangeNamePopup()}
              >
                <EditIcon />
              </IconButton>
            </div>
            <Button
              variant="contained"
              onClick={() => {
                this.togglePopup()
              }}
            >
              {t('caregiverGroup.addUser')}
            </Button>
          </Grid>
        </Grid>
        <hr />

        <Grid container spacing={1}>
          <Grid item sm={12}>
            {this.caregiverGroup.caregivers.length ||
            this.caregiverGroup.secretaries.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('table.fullName')}</TableCell>
                    <TableCell>{t('table.email')}</TableCell>
                    <TableCell>{t('caregiverGroup.role')}</TableCell>
                    <TableCell>{t('caregiverGroup.switchRole')}</TableCell>
                    <TableCell>{t('table.delete')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.caregiverGroup.caregivers.map(
                    ({ _id, fullName, email }, index) => (
                      <TableRow key={index}>
                        <TableCell>{fullName || '-'}</TableCell>
                        <TableCell>{email || '-'}</TableCell>
                        <TableCell>{t('caregiverGroup.caregiver')}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              this.toggleSwitchRolePopup(_id, email)
                            }}
                          >
                            <SyncRoundedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              this.confirmDeleteGroupUser(
                                this.caregiverGroup.id,
                                _id,
                              )
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                  {this.caregiverGroup.secretaries.map(
                    ({ _id, fullName, email }, index) => (
                      <TableRow key={index}>
                        <TableCell>{fullName || '-'}</TableCell>
                        <TableCell>{email || '-'}</TableCell>
                        <TableCell>{t('caregiverGroup.coordinator')}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              this.toggleSwitchRolePopup(_id, email)
                            }}
                          >
                            <SyncRoundedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              this.confirmDeleteGroupUser(
                                this.caregiverGroup.id,
                                _id,
                              )
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            ) : (
              <span>{t('caregiverGroup.noUsers')}</span>
            )}
          </Grid>
        </Grid>
        <AddContractUser
          open={this.popupCaregiver}
          close={this.togglePopup}
          addContractUser={addContractUser}
        />

        <SwitchUserRole
          open={this.popupSwitchRole}
          close={this.toggleSwitchRolePopup}
          switchContractUserRole={switchContractUserRole}
          userId={this.selectedUserId}
          userEmail={this.selectedUserEmail}
        />

        <ChangeGroupName
          open={this.popupChangeName}
          close={this.toggleChangeNamePopup}
          changeGroupName={changeGroupName}
          currentName={this.caregiverGroup.name}
        />

        <AlertDialog
          text={this.text}
          title={t('caregiverGroup.deleteUserConfirm')}
          open={this.open}
          actions={this.actions}
        />

        <AlertDialog
          text={this.text}
          title={confirmationText}
          open={!!confirmationText}
          actions={[
            <Button key={'confirmButton'} onClick={() => getData()}>
              {t('main.ok')}
            </Button>,
          ]}
        />

        {messageComponent}
      </div>
    )
  }
}

export default withTranslation()(CaregiverGroup)
