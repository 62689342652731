import { observable } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import request from '../utils/request'
import { CircularProgress } from '@material-ui/core'
import CaregiverGroupComponent from '../components/CaregiverGroup'

@observer
class CaregiverGroup extends Component {
  @observable caregiverGroup = {}
  @observable message = null
  @observable loading = false
  @observable confirmationText = null

  constructor(props) {
    super(props)
    const { contractId, caregiverGroupId } = props
    this.getCaregiverGroupUsers(contractId, caregiverGroupId)

    this.deleteCaregiverGroupUser = this.deleteCaregiverGroupUser.bind(this)
    this.addContractUser = this.addContractUser.bind(this)
    this.switchContractUserRole = this.switchContractUserRole.bind(this)
    this.changeGroupName = this.changeGroupName.bind(this)
  }

  async getCaregiverGroupUsers(contractId, caregiverGroupId) {
    this.loading = true
    return request('sherpa-admin', 'caregiverGroupUsers', {
      method: 'GET',
      query: {
        contractId,
        caregiverGroupId,
      },
    })
      .then((data) => {
        this.caregiverGroup = data
      })
      .catch((error) => {
        return error
      })
      .finally(() => (this.loading = false))
  }

  changeGroupName(name) {
    const { go, getData, contractId, caregiverGroupId } = this.props
    this.loading = true
    request('sherpa-admin', 'caregiverGroup', {
      method: 'PATCH',
      body: {
        name,
        contractId,
        groupId: caregiverGroupId,
      },
    }).then((data) => {
      this.loading = false
      if (data.message) {
        this.message = data.message
      } else {
        getData()
        go(
          `/caregiverGroup?contractId=${contractId}&caregiverGroupId=${caregiverGroupId}`,
        )
      }
    })
  }

  addContractUser(
    email,
    language = 'nl',
    role,
    firstname,
    insertion,
    lastname,
  ) {
    const { go, getData, contractId, caregiverGroupId } = this.props
    this.loading = true

    // add caregiver/coordinator to a contract
    request('sherpa-admin', 'caregiver', {
      method: 'POST',
      body: {
        email,
        language,
        role,
        contractId,
        caregiverGroupId,
        firstname,
        insertion,
        lastname,
      },
    }).then((data) => {
      this.loading = false
      if (data.message) {
        this.message = data.message
      } else {
        getData()
        go(
          `/caregiverGroup?contractId=${contractId}&caregiverGroupId=${caregiverGroupId}`,
        )
      }
    })
  }

  deleteCaregiverGroupUser(groupId, userId) {
    const { t, contractId } = this.props
    request('sherpa-admin', 'caregiver', {
      method: 'DELETE',
      query: {
        userId,
        caregiverGroupId: groupId,
        contractId,
      },
    }).then((data) => {
      if (data.message) {
        this.message = data.message
      } else {
        if (data.deletedFromContracts || data.status === 'deleted') {
          this.confirmationText = t('caregiverGroup.successfullyDeletedUser')
        } else if (data.status === 'lastCaregiver') {
          this.confirmationText = t('caregiverGroup.userIsActive')
        } else if (data.status === 'userDoesntExist') {
          this.confirmationText = t('caregiverGroup.userDoesNotExists')
        }
      }
    })
  }

  switchContractUserRole(userId, role) {
    const { contractId, caregiverGroupId, t } = this.props
    request('sherpa-admin', 'switchRole', {
      method: 'post',
      body: {
        contractId,
        caregiverGroupId,
        userId,
        role,
      },
    }).then((data) => {
      if (data.message) {
        this.message = data.message
      } else {
        if (data.status === 'success') {
          this.confirmationText = t('caregiverGroup.switchRoleConfirm')
        }
      }
    })
  }

  render() {
    const { getData } = this.props

    if (this.loading) return <CircularProgress />

    return (
      <CaregiverGroupComponent
        caregiverGroup={this.caregiverGroup}
        message={this.message}
        deleteCaregiverGroupUser={this.deleteCaregiverGroupUser}
        switchContractUserRole={this.switchContractUserRole}
        addContractUser={this.addContractUser}
        confirmationText={this.confirmationText}
        changeGroupName={this.changeGroupName}
        getData={getData}
      />
    )
  }
}

export default withTranslation()(CaregiverGroup)
