import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { StylesProvider, createGenerateClassName } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'

import theme from '../../config/theme'
import Router from '../Router'

@observer
export default class App extends Component {
  render() {
    const { access, initializing, user } = this.props
    const generateClassName = createGenerateClassName({
      productionPrefix: 'mssherpa',
      seed: 'dashboard',
    })

    return (
      <StylesProvider generateClassName={generateClassName} injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router user={user} initializing={initializing} access={access} />
        </MuiThemeProvider>
      </StylesProvider>
    )
  }
}
