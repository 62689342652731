import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import SupportComponent from '../components/Support'

@observer
class SupportPage extends Component {
  render() {
    return (
      <Fragment>
        <SupportComponent />
      </Fragment>
    )
  }
}

export default SupportPage
