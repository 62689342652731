import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import moment from 'moment'
import _ from 'lodash'
import { RECURRENT_CONTRACT_TYPE } from '../../models/Types'

import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { withTranslation } from 'react-i18next'

import Snackbar from '../Snackbar'
import AddCaregiverGroup from '../AddCaregiverGroup'
import AlertDialog from '../Dialog'

const styles = {
  tableLink: {
    cursor: 'pointer',
  },
}

@observer
class Contracts extends Component {
  @observable contract = {}
  @observable popupCaregiver = false
  @observable popupCaregiverGroup = false
  @observable open = false
  @observable actions = null

  constructor(props) {
    super(props)
    this.contract = props.contract
  }

  @action.bound
  togglePopup() {
    this.popupCaregiverGroup = !this.popupCaregiverGroup
  }

  @action.bound
  handleClose() {
    this.open = false
    this.actions = null
  }

  @action.bound
  confirmDeleteGroup(groupId) {
    const { t, deleteCaregiverGroup } = this.props
    this.open = true
    this.actions = [
      <Button key={'cancelButton'} onClick={this.handleClose} color="primary">
        {t('main.cancel')}
      </Button>,
      <Button key={'addButton'} onClick={() => deleteCaregiverGroup(groupId)}>
        {t('main.yes')}
      </Button>,
    ]
  }

  render() {
    const {
      go,
      admin,
      addCaregiverGroup,
      message,
      success,
      loading,
      getData,
      t,
    } = this.props
    let messageComponent = null

    if (loading) return <CircularProgress />

    if (message) {
      messageComponent = <Snackbar message={message} />
    }
    const totalUsersCount = this.contract.caregiverGroups.map(g=> g.appUsersSubscriptions).reduce((a,b) => a + b, 0)
    const totalActiveUsersCount = this.contract.caregiverGroups.map(g=> g.appUsersActiveSubscriptions).reduce((a,b) => a + b, 0)
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <p>
              {t('contracts.title')}: <b>{this.contract.title}</b>
            </p>
            <p>
              {t('contracts.organization')}: <b>{this.contract.client}</b>
            </p>
            <p>
              {t('contracts.contractSubs')}:
              <b>
                {this.contract.subscriptions ||
                  this.contract.totalSubscriptions}
              </b>
            </p>
            {this.contract.contractAdmins?.length > 0 &&
            this.contract.firstname &&
            this.contract.email &&
            this.contract.lastname ? (
              <>
                <p>
                  {t('contracts.adminName')}:
                  <b>
                    {' '}
                    {this.contract.firstname}
                    {this.contract.insertion
                      ? ` ${this.contract.insertion} `
                      : ' '}
                    {this.contract.lastname}
                  </b>
                </p>
                <p>
                  {t('contracts.adminEmail')}:<b> {this.contract.email}</b>
                </p>
              </>
            ) : (
              <p>{t('contracts.noAdminInContract')}</p>
            )}
          </Grid>

          <Grid item xs={4} id="commercial-fields">
            <p>
              {t('contracts.startDate')}:{' '}
              <b>
                {this.contract.contractStartDate
                  ? moment(this.contract.contractStartDate).format('DD-MM-YYYY')
                  : '-'}
              </b>
            </p>
            <p>
              {t('contracts.endDate')}:{' '}
              <b>
                {this.contract.contractEndDate
                  ? moment(this.contract.contractEndDate).format('DD-MM-YYYY')
                  : '-'}
              </b>
            </p>
            {this.contract.contractType === RECURRENT_CONTRACT_TYPE && (
              <div>
                <p>
                  {t('contracts.activationPeriod')}:{' '}
                  <b>
                    {this.contract.activationPeriod
                      ? this.contract.activationPeriod +
                        ' ' +
                        t('contracts.months')
                      : '-'}
                  </b>
                </p>
                <p>
                  {t('contracts.renewalPeriod')}:{' '}
                  <b>
                    {this.contract.renewalPeriod
                      ? this.contract.renewalPeriod +
                        ' ' +
                        t('contracts.months')
                      : '-'}
                  </b>
                </p>
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            {admin && (
              <Button
                variant="contained"
                onClick={() => {
                  go(`/editContract?contractId=${this.contract._id}`)
                }}
              >
                {t('editContract.editContract')}
              </Button>
            )}
          </Grid>
        </Grid>
        <hr />

        <h2>{t('contracts.caregiverGroups')}</h2>
        <Button
          variant="contained"
          onClick={() => {
            this.togglePopup()
          }}
        >
          {t('contracts.addCaregiverGroupButton')}
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('contracts.nameOfTheGroup')}</TableCell>
              <TableCell>{t('contracts.caregiverNum')}</TableCell>
              <TableCell>{t('contracts.coordinatorNum')}</TableCell>
              <TableCell>{t('contracts.appUsersNum')}</TableCell>
              <TableCell>{t('contracts.activeAppUsersNum')}</TableCell>
              <TableCell>{t('table.delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.contract.caregiverGroups.map((caregiverGroup, index) => (
              <TableRow key={index}>
                <TableCell
                  onClick={() =>
                    go(
                      `/caregiverGroup?contractId=${this.contract._id}&caregiverGroupId=${caregiverGroup.id}`,
                    )
                  }
                >
                  <span
                    style={styles.tableLink}
                    className="users-table-item-hover underline"
                  >
                    {caregiverGroup.name || '-'}
                  </span>
                </TableCell>
                <TableCell>{caregiverGroup.caregivers.length || '-'}</TableCell>
                <TableCell>
                  {caregiverGroup.secretaries.length || '-'}
                </TableCell>
                <TableCell>
                  {caregiverGroup.appUsersSubscriptions || '-'}
                </TableCell>
                <TableCell>
                  {caregiverGroup.appUsersActiveSubscriptions || '-'}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      this.confirmDeleteGroup(caregiverGroup.id)
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>{t('contracts.total')}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell>{totalUsersCount}</TableCell>
              <TableCell>{totalActiveUsersCount}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>

        <AddCaregiverGroup
          open={this.popupCaregiverGroup}
          close={this.togglePopup}
          addCaregiverGroup={addCaregiverGroup}
        />

        <AlertDialog
          text={this.text}
          title={t('caregiverGroup.deleteGroupConfirm')}
          open={this.open}
          actions={this.actions}
        />

        <AlertDialog
          text={this.text}
          title={success}
          open={!!success}
          actions={[
            <Button key={'confirmButton'} onClick={() => getData()}>
              {t('main.ok')}
            </Button>,
          ]}
        />

        {messageComponent}
      </div>
    )
  }
}

export default withTranslation()(Contracts)
