import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const Settings = ({ revalidateData }) => {
  const { t, i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(
    ['nl', 'en'].includes(localStorage.getItem('language'))
      ? localStorage.getItem('language')
      : 'nl',
  )
  const [confirmationDialogOpened, setSonfirmationDialogOpened] =
    useState(false)

  const changeLanguage = (event) => {
    const choosenLanguage = event?.target?.value
    setCurrentLanguage(choosenLanguage)
  }

  const saveCurrentLanguage = () => {
    setSonfirmationDialogOpened(false)

    // this is simple delay to enhance an user experience. Language change happen very fast otherwise
    setTimeout(() => {
      i18n.changeLanguage(currentLanguage)
      localStorage.setItem('language', currentLanguage)
      revalidateData()
    }, 100)
  }

  return (
    <>
      <FormControl>
        <FormLabel>{t('Settings.select')}</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-language-group-label"
          defaultValue="nl"
          name="language-radio-buttons-group"
          value={currentLanguage}
          onChange={changeLanguage}
        >
          <FormControlLabel
            value="nl"
            control={<Radio />}
            label={t('Settings.dutch')}
          />
          <FormControlLabel
            value="en"
            control={<Radio />}
            label={t('Settings.english')}
          />
        </RadioGroup>
        <Button
          style={{ marginTop: '30px' }}
          variant="contained"
          onClick={() => setSonfirmationDialogOpened(true)}
          disabled={
            currentLanguage === (localStorage.getItem('language') || 'nl')
          }
        >
          {t('main.save')}
        </Button>
      </FormControl>
      <Dialog
        open={confirmationDialogOpened}
        onClose={() => setSonfirmationDialogOpened(false)}
      >
        <DialogTitle>
          {t('Settings.languageWarning', {
            language:
              currentLanguage === 'nl'
                ? t('Settings.dutch')
                : t('Settings.english'),
          })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={saveCurrentLanguage} color="primary">
            {t('main.confirm')}
          </Button>
          <Button
            onClick={() => setSonfirmationDialogOpened(false)}
            color="primary"
          >
            {t('main.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Settings.propTypes = {
  revalidateData: PropTypes.func,
}

export default Settings
