import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { withTranslation } from 'react-i18next'

import WarningIcon from '@material-ui/icons/Warning'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

const styles = {
  warningBlock: {
    background: '#f5e8e5',
    border: 'solid 1px #d5563a',
    padding: 15,
  },
  icon: {
    fill: '#d5563a',
  },
  closeIcon: {
    float: 'right',
  },
}

@observer
class Warning extends Component {
  @observable display = 'block'

  @action.bound
  toggle() {
    this.display = this.display === 'block' ? 'none' : 'block'
  }

  render() {
    const { width, t } = this.props
    return (
      <div style={{ ...styles.warningBlock, width: width }}>
        <div>
          <WarningIcon style={styles.icon} />
          {this.display === 'block' ? (
            <RemoveIcon style={styles.closeIcon} onClick={this.toggle} />
          ) : (
            <AddIcon style={styles.closeIcon} onClick={this.toggle} />
          )}
        </div>
        <div style={{ display: this.display }}>
          {t('insight.noDataWarning')}
        </div>
      </div>
    )
  }
}

Warning.propTypes = {
  width: PropTypes.number,
}

export default withTranslation()(Warning)
