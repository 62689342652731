import { observable } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'

import request from '../utils/request'
import ContractComponent from '../components/Contract'
import { withTranslation } from 'react-i18next'

@observer
class Contract extends Component {
  @observable message = null
  @observable loading = false
  @observable success = null

  constructor(props) {
    super(props)
    const { contracts, contractId } = props
    this.contract = contracts.find((contract) => contract._id === contractId)
    this.addCaregiver = this.addCaregiver.bind(this)
    this.addCaregiverGroup = this.addCaregiverGroup.bind(this)
    this.deleteCaregiverGroup = this.deleteCaregiverGroup.bind(this)
  }

  addCaregiver(email, language) {
    const { go, getData } = this.props
    this.loading = true
    // add caregiver to contract
    request('sherpa-admin', 'caregiver', {
      method: 'POST',
      body: {
        email,
        language,
        contractId: this.contract._id,
      },
    }).then((data) => {
      this.loading = false
      if (data.message) {
        this.message = data.message
      } else {
        getData()
        go(`/contract?contractId=${this.contract._id}`)
      }
    })
  }

  addCaregiverGroup(groupName) {
    const { go, getData } = this.props
    this.loading = true
    request('sherpa-admin', 'caregiverGroup', {
      method: 'POST',
      body: {
        contractId: this.contract._id,
        name: groupName,
      },
    }).then((data) => {
      this.loading = false
      if (data.message) {
        this.message = data.message
      } else {
        getData()
        go(`/contract?contractId=${this.contract._id}`)
      }
    })
  }

  deleteCaregiverGroup(groupId) {
    const { t } = this.props
    request('sherpa-admin', 'caregiverGroup', {
      method: 'DELETE',
      body: {
        contractId: this.contract._id,
        groupId,
      },
    }).then((data) => {
      if (data.message) {
        this.message = data.message
      } else {
        this.success = t('contracts.successfullyDeletedGroup')
      }
    })
  }

  render() {
    const { getData, admin } = this.props
    return (
      <ContractComponent
        contract={this.contract}
        go={this.props.go}
        addCaregiver={this.addCaregiver}
        addCaregiverGroup={this.addCaregiverGroup}
        deleteCaregiverGroup={this.deleteCaregiverGroup}
        message={this.message}
        loading={this.loading}
        success={this.success}
        getData={getData}
        admin={admin}
      />
    )
  }
}
export default withTranslation()(Contract)
