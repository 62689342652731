import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import DescriptionIcon from '@material-ui/icons/Description'
import PeopleIcon from '@material-ui/icons/People'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import BusinessIcon from '@material-ui/icons/Business'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'

import MenuModel from '../../models/Menu'

const propTypes = {
  go: PropTypes.func,
  logout: PropTypes.func,
  classes: PropTypes.object,
}

const styles = {
  gradientBg: {
    background: 'linear-gradient(#A4F1CB 65%, #fff 100%)',
    height: '170px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '300px',
  },
  // profileName: {
  // fontFamily: '"Arvo", "Helvetica"',
  // fontSize: '1rem'
  // },
  // avatarImg: {
  // width: '73px',
  // height: '73px',
  // borderRadius: '50%',
  // margin: theme.spacing(2.3, 0, 1.9)
  // },
  menuListItem: {
    '& .MuiTypographyRoot': {
      fontFamily: '"Open Sans", "Helvetica"',
    },
  },
}

@observer
class Menu extends Component {
  render() {
    const { go, logout, admin, caregiver, user, contractAdmin, t } = this.props

    const menuItems = [
      {
        title: t('menu.eula'),
        icon: <DescriptionIcon />,
        onClick: () => {
          go('/eula')
          MenuModel.close()
        },
      },
      {
        title: t('menu.privacyStatement'),
        icon: <PlaylistAddCheckIcon />,
        onClick: () => {
          go('/privacyStatement')
          MenuModel.close()
        },
      },
      // disable temporary
      // {
      //   title: t('menu.contractInsight'),
      //   icon: <ShowChartIcon />,
      //   onClick: () => {
      //     go('/contractInsights')
      //     MenuModel.close()
      //   },
      // },
      {
        title: t('menu.support'),
        icon: <ContactSupportIcon />,
        onClick: () => {
          go('/support')
          MenuModel.close()
        },
      },
      {
        title: t('menu.about'),
        icon: <InfoRoundedIcon />,
        onClick: () => {
          go('/about')
          MenuModel.close()
        },
      },
      {
        title: t('menu.settings'),
        icon: <SettingsIcon />,
        onClick: () => {
          go('/settings')
          MenuModel.close()
        },
      },
      {
        title: t('menu.logout'),
        icon: <ExitToAppIcon />,
        onClick: () => {
          logout()
        },
      },
    ]

    if (admin) {
      menuItems.splice(0, 0, {
        title: t('menu.resetConsents'),
        icon: <CheckBoxOutlinedIcon />,
        onClick: () => {
          go('/resetConsents')
          MenuModel.close()
        },
      })

      menuItems.splice(0, 0, {
        title: t('menu.devices'),
        icon: <PhoneAndroidIcon />,
        onClick: () => {
          go('/devices')
          MenuModel.close()
        },
      })
    }

    if (admin || contractAdmin) {
      menuItems.splice(0, 0, {
        title: t('menu.contracts'),
        icon: <BusinessIcon />,
        onClick: () => {
          go('/contracts')
          MenuModel.close()
        },
      })
    }

    if (caregiver) {
      menuItems.splice(0, 0, {
        title: t('menu.patientOverview'),
        icon: <PeopleIcon />,
        onClick: () => {
          go('/')
          MenuModel.close()
        },
      })
    }

    return (
      <SwipeableDrawer
        anchor={'left'}
        open={MenuModel.open}
        onClose={MenuModel.close}
        onOpen={MenuModel.toggle}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <div style={styles.gradientBg}></div>
        <List>
          {menuItems.map((item, index) => (
            <ListItem button key={index} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} style={styles.menuListItem} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    )
  }
}

Menu.propTypes = propTypes

export default withTranslation()(Menu)
