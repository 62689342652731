import downloadBlob from './downloadBlob'
import auth0 from '../services/auth0'

const urlJoin = require('url-join')

export default async function request(serviceName, path, options) {
  options = {
    method: options.method || 'GET',
    query: options.query || {},
    body: options.body || null,
    headers: options.headers || {},
    baseUrl: options.baseUrl || process.env.SERVER_URL,
    authHeader: options.authHeader || true,
    blobName: options.blobName || null,
    urlencoded: options.urlencoded || false,
    useLocalhostAtPort: options.useLocalhostAtPort || null,
  }

  if (options.useLocalhostAtPort) {
    serviceName = ''
    options.baseUrl = `http://localhost:${options.useLocalhostAtPort}`
  }

  const fixedHeaders = options.authHeader
    ? {
        'Content-Type': options.urlencoded
          ? 'application/x-www-form-urlencoded'
          : 'application/json',
        Authorization: `bearer ${localStorage.id_token}`,
      }
    : {
        'Content-Type': options.urlencoded
          ? 'application/x-www-form-urlencoded'
          : 'application/json',
      }
  const result = await fetch(
    urlJoin(
      options.baseUrl,
      serviceName,
      path,
      `?${getQueryString({ ...options.query })}`,
    ),
    {
      method: options.method,
      body: options.body ? JSON.stringify(options.body) : options.body,
      headers: {
        ...fixedHeaders,
        ...options.headers,
      },
    },
  )

  if (!result.ok) {
    // logout user if unauthorized
    if (result.status === 401) {
      auth0.logout()
    }
  }

  if (options.blobName) {
    const blob = await result.blob()
    downloadBlob(blob, options.blobName)
    return
  }

  return await result.json()
}

function getQueryString(params = {}) {
  return Object.keys(params)
    .flatMap((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((element) => {
          return [encodeURIComponent(key) + '=' + encodeURIComponent(element)]
        })
      }

      return [encodeURIComponent(key) + '=' + encodeURIComponent(params[key])]
    })
    .join('&')
}

function encodeUrl(body) {
  const formBody = []

  for (const property in body) {
    const encodedKey = encodeURIComponent(property)
    const encodedValue = encodeURIComponent(body[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }

  return formBody.join('&')
}
