import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import InputLabel from '@material-ui/core/InputLabel'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

@observer
class ChangeGroupName extends Component {
  @observable name = ''

  constructor(props) {
    super(props)
    const { currentName } = props
    if (currentName) {
      this.name = currentName
    }
  }

  @action.bound
  handleChange = (name) => (event) => {
    this[name] = event.target.value
  }

  render() {
    const { open, close, changeGroupName, t } = this.props

    return (
      <>
        <Dialog open={open} fullWidth maxWidth="xs">
          <DialogTitle>{t('caregiverGroup.editNamePopupTitle')}</DialogTitle>
          <DialogContent>
            <InputLabel id="name">
              {t('caregiverGroup.editNamePopupLabel')}
            </InputLabel>
            <TextField
              id="name"
              type="name"
              value={this.name}
              onChange={this.handleChange('name')}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                changeGroupName(this.name)
              }}
              color="primary"
              disabled={this.name === ''}
            >
              {t('caregiverGroup.editNamePopupConfirm')}
            </Button>
            <Button
              onClick={() => {
                close()
              }}
            >
              {t('main.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

ChangeGroupName.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  changeGroupName: PropTypes.func,
  t: PropTypes.func,
  currentName: PropTypes.string,
}

export default withTranslation()(ChangeGroupName)
