import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import UsersComponent from '../components/Users'
import SubscriptionCount from '../components/SubscriptionCount'
import ContractSelect from '../components/ContractSelect'
import AddUserComponent from '../components/AddUser'
import request from '../utils/request'
import validate from '../config/validate'

@observer
class Users extends Component {
  @observable addUserModal = false
  @observable loading = false
  @observable error = null

  constructor(props) {
    super(props)

    this.deleteUser = this.deleteUser.bind(this)
    this.navigator = this.navigator.bind(this)
    this.addUser = this.addUser.bind(this)
    this.clearUserData = this.clearUserData.bind(this)
  }

  @action.bound
  handleAddUserClose() {
    this.addUserModal = false
  }

  @action.bound
  handleAddUserOpen() {
    this.addUserModal = true
  }

  resendInvite(email) {
    request('sherpa-caregiver', 'resendInvite', {
      method: 'POST',
      body: { email: email },
    })
  }

  async deleteUser(userId, contractId) {
    const { userModel } = this.props
    return request('sherpa-caregiver', 'appUser', {
      method: 'DELETE',
      query: { userId, contractId },
    }).then((data) => {
      userModel.deleteUser(userId.split('|')[1])
      return data
    })
  }

  async clearUserData(userId, contractName) {
    const { userModel } = this.props
    const contract =
      userModel?.contracts?.find(({ title }) => title === contractName) || ''

    return request('sherpa-caregiver', 'userData', {
      method: 'DELETE',
      query: {
        userId,
        contractId: contract._id,
      },
    })
      .then((data) => data)
      .catch((error) => {
        return error
      })
  }

  navigator(type) {
    const { userModel } = this.props
    userModel.getCaretakers(type)
  }

  addUser(email, contractId, language, inquiries, caregiverGroupId) {
    const { userModel, t } = this.props
    this.loading = true
    this.error = null

    if (inquiries.length === 0) {
      this.error = t('table.selectInquiriesError')
      this.loading = false
    } else if (!validate.validateEmail(email)) {
      this.error = t('table.emailError')
      this.loading = false
    } else {
      request('sherpa-caregiver', 'appUser', {
        method: 'POST',
        body: {
          email,
          contractId,
          language,
          inquiries,
          caregiverGroupId,
        },
      })
        .then((data) => {
          if (data.message) {
            this.error = data.message
          } else {
            userModel.addUser()
            this.handleAddUserClose()
          }
        })
        .catch((error) => {
          this.error = t('main.genericError')
        })
        .finally(() => (this.loading = false))
    }
  }

  render() {
    const { userModel, go } = this.props

    return (
      <Fragment>
        <ContractSelect user={userModel} />
        <SubscriptionCount user={userModel} />
        <UsersComponent
          userModel={userModel}
          users={userModel.caretakers}
          contracts={userModel.caregiverContracts}
          openAddUser={this.handleAddUserOpen}
          go={go}
          resendInvite={this.resendInvite}
          deleteUser={this.deleteUser}
          setActivtyUser={userModel.setActivityUser}
          lastPage={userModel.lastPage}
          pageNumber={userModel.pageNumber}
          navigator={this.navigator}
          clearUserData={this.clearUserData}
        />
        <AddUserComponent
          currentId={userModel._id}
          contracts={userModel.caregiverContracts}
          addUser={this.addUser}
          isOpen={this.addUserModal}
          handleClose={this.handleAddUserClose}
          loading={this.loading}
          error={this.error}
        />
      </Fragment>
    )
  }
}

Users.propTypes = {
  userModel: PropTypes.object.isRequired,
  go: PropTypes.func.isRequired,
}

export default withTranslation()(Users)
